import axios from "axios";

const checkIfUserIsLoggedIn = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_CHECK_IF_USER_IS_LOGGED_IN, { withCredentials: true })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default checkIfUserIsLoggedIn;
