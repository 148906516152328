import React, { useEffect, useState } from "react";
import "../Assets/Styles/Components/Header.css";
import { useCookies } from "react-cookie";

import { BsJustify as HamburgerIcon } from "react-icons/bs";
import { BsSearch as SearchIcon } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import ErrorWindow from "../Components/ErrorWindow";

import OdkrijLogo from "../Assets/Static/odkrij_si_logo_blue.svg"

import validateSearchQuery from "../Components/utils/validateSearchQuery";

import searchQueryApi from "../Services/searchQuery";

const Header = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["odkrijudata"]);
  const [loginRequired, setLoginRequired] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);
  const [cartItems, setCartItems] = useState(0);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
  });

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  //  #region on load
  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    if (firstName && lastName) {
      setLoginRequired(false);
      setUserData({
        firstName: JSON.parse(firstName),
        lastName: JSON.parse(lastName),
      });
    }

    const cart = JSON.parse(localStorage.getItem("ucart"));

    let quantity = 0;

    if (cart) {
      for (const item of cart) {
        quantity += item.quantity;
      }
    }
    setCartItems(quantity);
  }, []);
  // #region on cookie change
  useEffect(() => {
    if (
      !cookies?.odkrijudata &&
      !localStorage.getItem("firstName") &&
      !localStorage.getItem("lastName")
    ) {
      setLoginRequired(true);
    }
  }, [cookies?.odkrijudata]);

  // #region handle sidebar
  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // #region change search query
  const changeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  // #region submit seacrh query
  const submitSearchQuery = async () => {
    const { errorText, errorFound, errorTitle } =
      validateSearchQuery(searchQuery);

    if (errorFound) {
      setError(errorFound);
      setErrorText(errorText);
      setErrorTitle(errorTitle);
      return;
    }

    try {
      const sanitizedSQ = searchQuery.replaceAll(" ", "-");
      const response = await searchQueryApi(sanitizedSQ);
      navigate("/podjetje", {
        state: {
          query: sanitizedSQ,
          popularCompaniesArr: response.data.popularCompanies,
          companiesArr: response.data.companies,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };

  // #region Handle error window
  const handleErrorWindow = () => {
    setError(false);
  };

  // #region jsx
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}
      {showSidebar && (
        <Sidebar
          showsidebar={showSidebar}
          closesidebar={handleSidebar}
          logginrequired={loginRequired}
          user={userData}
        />
      )}
      <div className="header-box">
        <span onClick={() => navigate("/")} className="logo-box">

          <img alt="Logotip podjetja odkrij.si" src={OdkrijLogo} />
        </span>
        <span className="searchbar-box">
          <input
            className="searchbar"
            value={searchQuery}
            onChange={changeSearchQuery}
            type="text"
            placeholder="Odkrij..."
          />
          <SearchIcon className="search-icon" onClick={submitSearchQuery} />
        </span>
        <span className="login-info-box">
          <Link className="header-link" to={
            loginRequired ? "/prijavaUporabnika" : "/upravljanjeUporabnika"
          }>
            {loginRequired ||
            userData.firstName.length === 0 ||
            userData.lastName.length === 0
              ? "Prijava"
              : `${userData.firstName} ${userData.lastName}`}
          </Link>
        </span>
        <Link to={"/odkrijVozicek"} className="shopping-cart-box">
          <span className="cart-items-count">{cartItems}</span>
        </Link>
        <span className="hamburger-menu-box">
          <HamburgerIcon
            className="open-hamburger-icon"
            onClick={handleSidebar}
          />
        </span>
      </div>
    </>
  );
};

export default Header;
