import React, { useEffect, useState } from "react";
import InputBox from "./InputBox";
import validateInvoiceReciever from "./utils/ValidateInvoiceReciever";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import ErrorWindow from "./ErrorWindow";
import Loader from "./Loader";
import CheckBox from "./CheckBox";

import "../Assets/Styles/Components/UserInvoiceReciever.css";

import postData from "../Services/postData";

const UserInvoiceReciever = (props) => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [invoiceReciever, setInvoiceReciever] = useState({
    invoiceRecieverName: "",
    invoiceRecieverLastName: "",
    invoiceRecieverTaxNumber: "",
    invoiceRecieverRegistrationNumber: "",
    invoiceRecieverEmail: "",
    invoiceRecieverPhoneNumber: "",
    invoiceRecieverTaxPayer: false,
    companyId: props.companyid,
  });

  // #region CHANGE INPUT
  const changeInput = (e, datatype) => {
    if (e.target.name === "invoiceRecieverTaxPayer") {
      setInvoiceReciever({
        ...invoiceReciever,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInvoiceReciever({
      ...invoiceReciever,
      [e.target.name]: e.target.value,
    });
  };

  // #region SUBMIT DATA
  const submitData = async () => {
    setIsLoading(true);
    const { errorFound, errorText, errorTitle } =
      validateInvoiceReciever(invoiceReciever);

    if (errorFound) {
      setIsLoading(false);
      setError(errorFound);
      setErrorText(errorText);
      setErrorTitle(errorTitle);
      return;
    }

    props.saveinvoicereciever(invoiceReciever);
    props.handleinvoicerecieverwindow(true);

    // try {
    //   const response = await postData(
    //     process.env.REACT_APP_INSERT_NEW_INVOICE_RECIEVER_URL,
    //     { invoiceReciever }
    //   );
    //   if (response.data.invoiceRecieverInserted) {
    //     props.handleinvoicerecieverwindow(true);
    //   }
    //   setIsLoading(false);
    // } catch (err) {
    //   setIsLoading(false);
    //   setError(true);
    //   setErrorText(err?.response?.data?.message);
    //   setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    // }
    setIsLoading(false);
  };

  const enterPress = (e) => {
    if (e.key === "Enter") {
      submitData();
    }
  };

  // #region USE EFFECT
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="user-invoice-receiver-page" onKeyDown={enterPress}>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={() => setError(!error)}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}
      <div className="user-invoice-reciever-box">
        <div className="invoice-reciever-title">Prejemnik računa</div>
        <InputBox
          label="*Ime podjetja/osebe:"
          placeholder="Vnesi ime podjetja/osebe..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverName"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <InputBox
          label="Priimek osebe:"
          placeholder="Vnesi priimek osebe (če je podjetje izpusti)..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverLastName"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <InputBox
          label="*Davčna št:"
          placeholder="Vnesi davčno številko..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverTaxNumber"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <InputBox
          label="*Matična št:"
          placeholder="Vnesi matično številko..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverRegistrationNumber"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <CheckBox
          label="*Davčni zavezanec "
          labelclass="label"
          datatype="basic"
          name="invoiceRecieverTaxPayer"
          value="true"
          onchange={changeInput}
        />
        <InputBox
          label="*E-naslov:"
          placeholder="Vnesi e-naslov..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverEmail"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <InputBox
          label="*Telefonska številka:"
          placeholder="Vnesi telefonsko številko..."
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container"
          name="invoiceRecieverPhoneNumber"
          datatype="basic"
          type="text"
          onchange={changeInput}
        />
        <div className="invoice-reciever-button-box">
          <button onClick={submitData} className="button-1">
            Naprej
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInvoiceReciever;
