import React, { useState, useEffect } from "react";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InputBox from "../Components/InputBox";
import TextArea from "../Components/TextArea";
import Loader from "../Components/Loader";
import ErrorWindow from "../Components/ErrorWindow";

import ValidateOrderServiceData from "../Components/utils/ValidateOrderServiceData";
import postDataTwoParams from "../Services/postDataTwoParams";

import "../Assets/Styles/Pages/OrderService.css";
import InputDate from "../Components/InputDate";

const OrderService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceName, setServiceName] = useState("");
  const [params, setParam] = useState(null);
  const [input, setInput] = useState({});

  const [charCount, setCharCount] = useState(0);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // #region ON LOAD
  useEffect(() => {
    const pathArr = location.pathname.split("/");

    if (location.state?.id) {
      setParam(location.state.id);
      const name = decodeURIComponent(pathArr[pathArr.length - 1]);
      setServiceName(name.replaceAll("_", " "));
      return;
    }
    const name = decodeURIComponent(pathArr[pathArr.length - 1]);

    setParam(name);
    setServiceName(name.replaceAll("_", " "));
  }, [location.pathname]);

  const orderService = async () => {
    setIsLoading(true);
    const { errorFound, errorTitle, errorText } =
      ValidateOrderServiceData(input);

    if (errorFound) {
      setIsLoading(false);
      setError(true);
      setErrorText(errorText);
      setErrorTitle(errorTitle);
      return;
    }
    try {
      const response = await postDataTwoParams(
        process.env.REACT_APP_ORDER_SERVICE_URL,
        params,
        input
      );

      // console.log(response);
      if (response.data.serviceOrderStatus) {
        navigate(-1);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
      if (error?.response?.data) {
        setErrorText(error.response.data.message);
        setErrorTitle(ErrorCodesParser(error.response.data.errorCode));
      }
    }
  };

  // #region INPUT CHANGE
  const changeInput = (e, datatype) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "text") {
      setCharCount(e.target.value.length);
    }
  };

  return (
    <>
      {error && (
        <ErrorWindow
          btntext="RAZUMEM"
          onclick={() => setError(!error)}
          title={errorTitle}
          text={errorText}
        />
      )}

      {isLoading && !error && <Loader />}
      <Header />
      <div className="order-service-page">
        <div className="order-service-title">
          Povpraševanje storitve ({serviceName})
        </div>
        <div className="order-service-form">
          <InputBox
            label="Kontakt: "
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-left"
            name="contact"
            datatype="basic"
            placeholder="E-naslov ali TEL..."
            type="text"
            onchange={changeInput}
          />
          <InputBox
            label="Naslov: "
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-left"
            name="address"
            datatype="basic"
            placeholder="Slovenska ulica 1..."
            type="text"
            onchange={changeInput}
          />
          <InputBox
            label="Pošta: "
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-left"
            name="post"
            datatype="basic"
            placeholder="0000 posta..."
            type="text"
            onchange={changeInput}
          />
          <TextArea
            label="Podrobnejši opis: "
            id="textarea"
            name="text"
            rows="10"
            cols="50"
            placeholder="Vnesi podrobnejši opis (kaj je potrebno, velikost, dolžino, čas)..."
            datatype="description"
            char={charCount}
            minChar="200"
            labelclass="label"
            onchange={changeInput}
          />
          <InputDate
            label="Datum izvedbe: "
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-left"
            name="executionDate"
            datatype="basic"
            type="date"
            onchange={changeInput}
          />
        </div>
        <div className="order-service-button-box">
          <button onClick={() => navigate(-1)} className="button-3">
            NAZAJ
          </button>
          <button onClick={orderService} className="button-1">
            ODDAJ
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

// .textarea-container{
//   min-width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

export default OrderService;
