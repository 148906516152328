import React, { useEffect } from "react";
import "./Assets/Styles/App.css";
import { useCookies } from "react-cookie";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import CompanyLogin from "./Pages/CompanyLogin";
import CompanyAdministration from "./Pages/CompanyAdministration";
import EditOffer from "./Pages/EditOffer";
import AddNewOffer from "./Pages/AddNewOffer";
import RemoveOffer from "./Pages/RemoveOffer";
import UserLogin from "./Pages/UserLogin";
import UserRegister from "./Pages/UserRegister";
import ForgottenPassword from "./Pages/ForgottenPassword";
import CompanyViaUrl from "./Pages/CompanyViaUrl";
import DisplayCompanies from "./Pages/DisplayCompanies";
import UserAdministration from "./Pages/UserAdministration";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Contact from "./Pages/Contact";
import AboutUs from "./Pages/AboutUs";
import OdkrijOffer from "./Pages/OdkrijOffer";
import FinishOrder from "./Pages/FinishOrder";
import ViewCart from "./Pages/ViewCart";
import OrderService from "./Pages/OrderService";
import Slovenia from "./Pages/Slovenia";

import NotFoundPage from "./Pages/ErrorPages/NotFoundPage";
import OfferViaUrl from "./Pages/OfferViaUrl";
import GetOffers from "./Pages/GetOffers";

const router = createBrowserRouter([
  { path: "/", element: <Slovenia />, errorElement: <NotFoundPage /> },
  {
    path: "/prijavaPodjetja",
    element: <CompanyLogin />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjePodjetja",
    element: <CompanyAdministration />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjePonudbe",
    element: <EditOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/dodajPonudbo",
    element: <AddNewOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odstraniPonudbo",
    element: <RemoveOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/prijavaUporabnika",
    element: <UserLogin />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/registracijaUporabnika",
    element: <UserRegister />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/pozabljenoGeslo",
    element: <ForgottenPassword />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/podjetje",
    element: <DisplayCompanies />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/podjetje/:imePodjetja",
    element: <CompanyViaUrl />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/podjetje/:imePodjetja/:imePonudbe",
    element: <OfferViaUrl />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/upravljanjeUporabnika",
    element: <UserAdministration />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/politikaZasebnosti",
    element: <PrivacyPolicy />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijNas",
    element: <AboutUs />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijPonudbo",
    element: <OdkrijOffer />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/zakljuciNarocilo",
    element: <FinishOrder />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/odkrijVozicek",
    element: <ViewCart />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/narociloStoritve/:imeNarocila",
    element: <OrderService />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "pridobivanjePonudbe",
    element: <GetOffers />,
    errorElement: <NotFoundPage />,
  }
]);

function App() {
  const [cookies] = useCookies(["odkrijudata"]);
  useEffect(() => {
    if (!cookies?.odkrijudata) {
      localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
    }
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
