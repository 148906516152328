import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import { Link, useNavigate } from "react-router-dom";
import ErrorWindow from "../Components/ErrorWindow";
import ValidateUserData from "../Components/utils/ValidateUserData";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import loginUserApi from "../Services/loginUser";
import VerifyEmail from "../Components/VerifyEmail";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Assets/Styles/Pages/UserLogin.css";
import "../Assets/Styles/Components/ButtonStyle.css";

const UserLogin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({});
  const changeInput = (e, datatype) => {
    setUser({
      ...user,
      [datatype]: {
        ...user[datatype],
        [e.target.name]: e.target.value,
      },
    });
  };

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [openVerifyEmailWindow, setOpenVerifyEmailWindow] = useState(false);

  const loginUser = async () => {
    setIsLoading(true);
    const { errorFound, errorTitle, errorText } = ValidateUserData(user);

    setError(errorFound);
    setError(errorTitle);
    if (errorTitle === "GESLO UPORABNIKA") {
      setErrorText("Napačna oblika gesla poskusite znova")
    } else {
      setErrorText(errorText);
    }

    if (!errorFound) {
      try {
        const response = await loginUserApi(user.basic);

        if (response.data?.verifyRequired) {
          setOpenVerifyEmailWindow(!openVerifyEmailWindow);
          setIsLoading(false);
          return;
        }

        if (response?.status === 200) {
          setIsLoading(false);
          localStorage.setItem(
            "firstName",
            JSON.stringify(response.data.user.firstName)
          );
          localStorage.setItem(
            "lastName",
            JSON.stringify(response.data.user.lastName)
          );
          navigate(-1);
        }
        if (response?.status === 209) {
          setIsLoading(false);
          setError(true);
          setErrorText(response.data.message);
          setErrorTitle(ErrorCodesParser(response.data.errorCode));
        }
      } catch (err) {
        setIsLoading(false);
        // console.log(err);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
  };

  const handleVerifyEmailWindow = () => {
    setOpenVerifyEmailWindow(!openVerifyEmailWindow);
  };

  const submitForm = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  return (
    <div className="login-page" onKeyDown={submitForm}>
      <Header />
      {!error && !isLoading && openVerifyEmailWindow && (
        <VerifyEmail
          email={user.basic.email}
          handleverifyemailwindow={handleVerifyEmailWindow}
          changeemail={false}
        />
      )}

      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={() => setError(!error)}
          btntext={"RAZUMEM"}
        />
      )}
      <div className="login-box">
        <h1 className="title">PRIJAVA UPORABNIKA</h1>
        <div className="input-fields">
          <InputBox
            label="E-naslov: "
            id="input-bar-1"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="email"
            placeholder="Vnesi E-naslov..."
            type="text"
            datatype="basic"
            onchange={changeInput}
          />
          <InputBox
            label="Geslo: "
            id="input-bar-2"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-center"
            name="password"
            datatype="basic"
            placeholder="Vnesli geslo..."
            type={showPassword ? "text" : "password"}
            onchange={changeInput}
            showpassword={() => setShowPassword(!showPassword)}
          />
        </div>
        <div className="links-box">
          <Link className="link" to={"/pozabljenoGeslo"}>
            Pozabil geslo
          </Link>
          <Link className="link" to={"/registracijaUporabnika"}>
            Nimam računa
          </Link>
        </div>
        <button className="button-1" type="button" onClick={loginUser}>
          PRIJAVA
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default UserLogin;
