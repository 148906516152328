import {
  DATE_REGEX,
  EMAIL_REGEX,
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  POST_REGEX,
  STREET_REGEX,
} from "../../Constants/RegexPatterns";

import { badWords } from "../../Constants/badWords";

const ValidateOrderServiceData = (input) => {
  let errorFound = false;
  let errorTitle = "";
  let errorText = "";
  for (const attribute in input) {
    if (attribute === "contact") {
      if (
        !EMAIL_REGEX.test(input[attribute]) &&
        !PHONE_NUMBER_REGEX.test(input[attribute])
      ) {
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom, oblika mora biti podobna (janez.novak@odkrij.si) ali (000 000 000)";
        errorTitle = "KONTAKT";
      }
    }

    if (attribute === "address") {
      if (!STREET_REGEX.test(input[attribute])) {
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom, oblika mora biti podobna (Slovenska ulica 1)";
        errorTitle = "NASLOV";
      }
    }

    if (attribute === "post") {
      if (!POST_REGEX.test(input[attribute])) {
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom, oblika mora biti podobna (0000 Kraj)";
        errorTitle = "POŠTA";
      }
    }

    if (attribute === "text") {
      if (containsBadWord(input[attribute])) {
        errorFound = true;
        errorText =
          "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
        errorTitle = `PODROBNEJŠI OPIS`;
      }
    }
    if (attribute === "executionDate") {
      if (!DATE_REGEX.test(input[attribute])) {
        // console.log(input[attribute])
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom, oblika mora biti podobna (yyyy-mm-dd)";
        errorTitle = `DATUM IZVEDBE`;
      }
    }
  }

  return { errorFound, errorText, errorTitle };
};

export default ValidateOrderServiceData;

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase())) {
      return true;
    }
  }

  return false;
};
