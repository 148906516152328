import React, { useEffect, useRef, useState } from "react";
import {
  BsBoxFill as ProductIcon,
  BsWrench as ServiceIcon,
} from "react-icons/bs";

import validateCompanyData from "../Components/utils/ValidateCompanyData";

import offerCategoryParser from "../Components/utils/OfferCategoryParser";

import InputBox from "../Components/InputBox";
import DropDownMenu from "../Components/DropDownMenu";
import TextArea from "../Components/TextArea";
import ImageInput from "../Components/ImageInput";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import insertNewCompany from "../Services/insertNewCompany";
import insertCompanyImages from "../Services/insertCompanyImages";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import { DIACRITICS, SPECIAL_CHARS } from "../Constants/RegexPatterns";
import AddProduct from "../Components/AddProduct";
import AddService from "../Components/AddService";
import validateOfferData from "../Components/utils/ValidateOfferData";
import insertNewOffer from "../Services/insertNewOffer";
import UpdateOfferWindow from "../Components/UpdateOfferWindow";
import { useNavigate } from "react-router-dom";

import "../Assets/Styles/Pages/CompanyLogin.css";
import "../Assets/Styles/Config/Icons.css";
import CheckBox from "../Components/CheckBox";
import Header from "../Components/Header";
import UserInvoiceReciever from "../Components/UserInvoiceReciever";
import AddCompanyData from "../Components/AddCompanyData";
import AddCompanyImages from "../Components/AddCompanyImages";
import validateInvoiceReciever from "../Components/utils/ValidateInvoiceReciever";
import postData from "../Services/postData";
import checkIfUserIsLoggedIn from "../Services/checkIfUserIsLoggedIn";

const CompanyLogin = () => {
  // #region VARIABLES
  const [charCount, setCharCount] = useState(0);
  const [company, setCompany] = useState(null);
  const [invoiceRecieverStatus, setInvoiceRecieverStatus] = useState(false);

  const [imageName, setImageName] = useState(undefined);

  const [flagsObj, setFlagsObj] = useState({
    intro: false,
    logo: false,
    productsImg: false,
    service: false,
    product: false,
  });

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [companyId, setCompanyId] = useState(undefined);

  const [companyOffer, setCompanyOffer] = useState({});
  const [companyOfferArr, setCompanyOfferArr] = useState({
    service: [],
    product: [],
  });
  const [charCountOffer, setCharCountOffer] = useState(0);
  const [charCountReturn, setCharCountReturn] = useState(0);
  const [isCheckedWarranty, setIsCheckedWarranty] = useState(false);
  const [isCheckedReturn, setIsCheckedReturn] = useState(false);
  const [imageNameOffer, setImageNameOffer] = useState(undefined);
  const [isCheckedOrderItem, setIsCheckedOrderItem] = useState(false);

  const [addProductWindow, setAddProductWindow] = useState(false);
  const [addServiceWindow, setAddServiceWindow] = useState(false);

  const [oldImageName, setOldImageName] = useState(undefined);

  const [showImages, setShowImages] = useState(false);
  const [showOffer, setShowOffer] = useState(false);

  const [logoImg, setLogoImg] = useState(false);
  const [introImg, setIntroImg] = useState(false);
  const [productsImg, setProductsImg] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isOpenUpdateOfferWindow, setIsOpenUpdateOfferWindow] = useState(false);

  const [invoiceReciever, setInvoiceReciever] = useState({
    invoiceRecieverName: "",
    invoiceRecieverLastName: "",
    invoiceRecieverTaxNumber: "",
    invoiceRecieverRegistrationNumber: "",
    invoiceRecieverEmail: "",
    invoiceRecieverPhoneNumber: "",
    invoiceRecieverTaxPayer: false,
    companyId: null,
  });

  const formData = useRef(new FormData());

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // #region CHECK IF USER IS LOGGED IN
  const checkForLogin = async () => {
    try {
      await checkIfUserIsLoggedIn();
    } catch (err) {
      setIsLoading(false);
      setError(true);
      setErrorText(err?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    }
  };
  // #region HANDLE WINDOWS
  // #region INVOICE RECIEVER
  const handleInvoiceRecieverWindow = (status) => {
    setInvoiceRecieverStatus(status);
  };
  // #region ERROR
  const handleErrorWindow = () => {
    setError(false);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
  };

  // #region SAVE IMAGES
  const saveImages = (formDataObj) => {
    const newImagesArr = formDataObj.getAll("images");
    const oldImagesArr = formData.current.getAll("images");

    // console.log(newImagesArr);
    for (const newImage of newImagesArr) {
      // console.log(newImage);
      const imageTypeNew = newImage.name.split("-").pop();

      const index = oldImagesArr.findIndex(
        (oldImage) => oldImage.name.split("-").pop() === imageTypeNew
      );

      if (index !== -1) {
        oldImagesArr[index] = newImage;
      } else {
        oldImagesArr.push(newImage);
      }
    }

    formData.current.delete("images");

    for (const image of oldImagesArr) {
      formData.current.append("images", image);
    }

    // TODO: Spremeni pageNumber
    setPageNumber(0);
  };

  // #region OFFER INPUT
  const changeOfferInput = (e, datatype) => {
    if (datatype === "image") {
      const type = e.name.split("-");

      if (flagsObj[type[2]]) {
        let images = formData.current.getAll("images");
        formData.current.delete("images");

        images.pop();

        images.push(e);

        for (const image of images) {
          formData.current.append("images", image);
        }
      } else {
        setFlagsObj({ ...flagsObj, [type[2]]: true });
        formData.current.append("images", e);
      }
    } else if (datatype === "flags") {
      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.checked,
        },
      });
      if (e.target.name === "return") {
        setIsCheckedReturn(!isCheckedReturn);
      }

      if (e.target.name === "orderItem") {
        // console.log(isCheckedOrderItem);
        setIsCheckedOrderItem(!isCheckedOrderItem);
      }

      if (e.target.name === "warranty") {
        setIsCheckedWarranty(!isCheckedWarranty);
      }
    } else {
      if (e.target.name === "name") {
        const sanitizedName = e.target.value
          .toLowerCase()
          .replace(DIACRITICS, "")
          .replace(SPECIAL_CHARS, "");
        setImageNameOffer(`${companyId}-${sanitizedName}`);
      }

      setCompanyOffer({
        ...companyOffer,
        [datatype]: {
          ...companyOffer[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (e.target.name === "text") {
        setCharCountOffer(e.target.value.length);
      }
      if (e.target.name === "returnConditions") {
        setCharCountReturn(e.target.value.length);
      }
    }
  };

  // #region ADD OFFER
  const addOffer = (offertype) => {
    if (!flagsObj[offertype]) {
      setError(true);
      setErrorText(`Vašemu obrazcu manjkajo nekateri podatki!`);
      setErrorTitle("SLIKA PONUDBE");
      return;
    }

    for (const offerArr in companyOfferArr) {
      for (const offer of companyOfferArr[offerArr]) {
        if (offer.basic.name === companyOffer.basic.name) {
          setError(true);
          setErrorText(`Ponudba z enakimi podatki že obstaja`);
          setErrorTitle("DUPLIKAT PONUDBE");
          setOldImageName(
            `${companyId}-${offer.basic.name
              .toLowerCase()
              .replace(DIACRITICS, "")
              .replace(SPECIAL_CHARS, "")}-${offertype}`
          );
          return;
        }
      }
    }

    if (oldImageName) {
      const images = formData.current.getAll("images");

      const newImageName = `${companyId}-${companyOffer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offertype}`;

      let imageIndex = -1;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          if (imageIndex === -1) {
            imageIndex = index;
            return image;
          }

          const tempFile = new File([image], newImageName, {
            type: image.type,
            lastModified: new Date(),
          });
          return tempFile;
        }
        return image;
      });
      formData.current.delete("images");
      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    //TODO: PREVERI ČE SO VSI PODATKI

    setCompanyOfferArr((prevState) => ({
      ...prevState,
      [offertype]: [...prevState[offertype], companyOffer],
    }));

    setFlagsObj({ ...flagsObj, [offertype]: false });

    setIsCheckedReturn(false);
    setIsCheckedWarranty(false);
    setAddProductWindow(false);
    setAddServiceWindow(false);
    setCompany(undefined);
    setOldImageName(undefined);
  };

  // #region DELETE OFFER
  const deleteOffer = (deleteOffer, offertype) => {
    let images = formData.current.getAll("images");

    formData.current.delete("images");

    let deleteImageName = deleteOffer.basic.name
      .toLowerCase()
      .replace(DIACRITICS, "")
      .replace(SPECIAL_CHARS, "");

    deleteImageName = `${companyId}-${deleteImageName}-${offertype}`;

    const newImages = images.filter((image) => image.name !== deleteImageName);

    const newOffer = companyOfferArr[offertype].filter(
      (offer) => offer.basic.name !== deleteOffer.basic.name
    );

    for (const image of newImages) {
      formData.current.append("images", image);
    }

    setCompanyOfferArr({
      ...companyOfferArr,
      [offertype]: newOffer,
    });
  };

  // #region SUBMIT OFFER
  const submitOffer = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const { errorFound, errorText, errorTitle } =
      validateOfferData(companyOfferArr);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    // console.log(formData.current.getAll("images"))
    if (
      formData.current.getAll("images").length !==
      companyOfferArr.service.length + companyOfferArr.product.length
    ) {
      setIsLoading(false);
      setError(true);
      setErrorText(
        "V obrazcu ste pozabili vnesti slike ponudbe preverite podatke ali kontaktirajte našo podporno ekipo."
      );
      setErrorTitle("MANJKAJO SLIKE PODJETJA");
      return;
    }
    if (!errorFound && companyId) {
      try {
        const response = await insertNewOffer(companyOfferArr, companyId);
        if (response.status === 200) {
          if (response?.data?.affectedRows > 0) {
            await insertCompanyImages(formData.current);
          }

          if (response?.data?.duplicateArr.length > 0) {
            let text = "";
            for (const item of response.data.duplicateArr) {
              text += item + ", ";
            }
            const displayText = text.slice(0, -2);
            setIsLoading(false);
            setError(true);
            setErrorText(`Ponudbe: (${displayText}) že obstajajo.`);
            setErrorTitle("DUPLIKAT PONUDBE");
          }
          navigate("/", { replace: true });
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        // console.log(err);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
  };

  // #region UPDATE OFFER WINDOW
  const handleUpdateOfferWindow = (offerId) => {
    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  const saveOffer = (
    newOffer,
    newImage,
    newImageUrl,
    offerType,
    oldOfferName,
    companyId
  ) => {
    //TODO: VALIDATE DATA

    if (newOffer.basic.name !== oldOfferName) {
      const images = formData.current.getAll("images");
      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;
      const newImageName = `${companyId}-${newOffer.basic.name
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          const tempFile = new File([image], newImageName, {
            type: image.type,
            lastModified: new Date(),
          });
          return tempFile;
        }
        return image;
      });
      formData.current.delete("images");
      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    if (newImage) {
      const images = formData.current.getAll("images");

      const oldImageName = `${companyId}-${oldOfferName
        .toLowerCase()
        .replace(DIACRITICS, "")
        .replace(SPECIAL_CHARS, "")}-${offerType}`;

      const newimagesArr = images.map((image, index) => {
        if (image.name === oldImageName) {
          return newImageUrl.get("images");
        } else {
          return image;
        }
      });

      formData.current.delete("images");

      for (const image of newimagesArr) {
        formData.current.append("images", image);
      }
    }

    const indexOfOldOffer = companyOfferArr[offerType].findIndex(
      (offer) => offer.basic.name === oldOfferName
    );

    const newOfferArr = companyOfferArr[offerType].map((itemOfArray, index) => {
      if (index === indexOfOldOffer) {
        return newOffer;
      } else {
        return itemOfArray;
      }
    });
    setCompanyOfferArr({
      ...companyOfferArr,
      [offerType]: newOfferArr,
    });

    setIsOpenUpdateOfferWindow(!isOpenUpdateOfferWindow);
  };

  // #region Validate Company
  // TODO: Login Company -> prvo pošlji company, potem invoiceReciever, potem pa še slike, če je vse ok setPageNumber(3); (DODAJ PRI 2 gumbih, z klikom na gumb naj prikaže warning, da se bo podjetje prijavilo (ni poti nazaj))
  const handleCompanyValidation = () => {
    // TODO: Ide skoz da so vse vnesene
    const { errorFound, errorText, errorTitle } = validateCompanyData(company);

    if (errorFound) {
      setError(errorFound);
      setErrorText(errorText);
      setErrorTitle(errorTitle);
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };

  // #region Validate Invoice Reciever
  const handleInputRecieverValidation = () => {
    const { errorFound, errorText, errorTitle } =
      validateInvoiceReciever(invoiceReciever);

    if (errorFound) {
      setError(errorFound);
      setErrorText(errorText);
      setErrorTitle(errorTitle);
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };
  // #region LOGIN COMPANY
  const loginCompany = async (redirect) => {
    setIsLoading(true);
    if (!company) {
      setIsLoading(false);
      setError(true);
      setErrorText(
        "Pri vaši prijavi niste navedli vseh podatkov, ki so potrebni za dokončanje prijave."
      );
      setErrorTitle("MANJKAJOČE PODATKI");
      return;
    }

    if (!handleCompanyValidation()) {
      setIsLoading(false);
      return;
    }

    if (!company.legal.invoiceReceiver && !handleInputRecieverValidation()) {
      setIsLoading(false);
      return;
    }

    if (formData.current.getAll("images").length !== 3) {
      setIsLoading(false);
      setError(true);
      setErrorText(
        "Pri vaši prijavi niste, nastavili vseh slik podjetja, slike podjetja so potrebne za dokončanje prijave!"
      );
      setErrorTitle("MANJKAJOČE SLIKE PODJETJA");
      return;
    }

    try {
      const companyResponse = await insertNewCompany(company);
      const companyId = companyResponse?.data?.id;
      setCompanyId(companyId);

      invoiceReciever.companyId = companyId;

      if (!company.legal.invoiceReceiver) {
        const invoiceReceiverResponse = await postData(
          process.env.REACT_APP_INSERT_NEW_INVOICE_RECIEVER_URL,
          { invoiceReciever }
        );
        setIsLoading(false);
        if (invoiceReceiverResponse.data.invoiceRecieverInserted) {
          const imageName = `${companyId}-${company?.legal?.taxNumber}`;
          const imagesArr = formData.current.getAll("images");

          formData.current.delete("images");

          for (const image of imagesArr) {
            const imageType = image.name.split("-").pop();

            const renamedImage = new File(
              [image],
              `${imageName}-${imageType}`,
              {
                type: image.type,
              }
            );

            formData.current.append("images", renamedImage);
          }

          const imagesResponse = await insertCompanyImages(formData.current);

          if (imagesResponse.status(200)) {
            setIsLoading(false);
            formData.current.delete("images");
            if (redirect) {
              setPageNumber(3);
            } else {
              navigate(-1);
            }
          }

          setIsLoading(false);
        }
      } else {
        const imageName = `${companyId}-${company.legal.taxNumber}`;
        const imagesArr = formData.current.getAll("images");

        formData.current.delete("images");

        for (const image of imagesArr) {
          const imageType = image.name.split("-").pop();

          const renamedImage = new File([image], `${imageName}-${imageType}`, {
            type: image.type,
          });

          formData.current.append("images", renamedImage);
        }

        const imagesResponse = await insertCompanyImages(formData.current);

        // console.log(imagesResponse);

        if (imagesResponse.status === 200) {
          setIsLoading(false);
          formData.current.delete("images");
          if (redirect) {
            setPageNumber(3);
          } else {
            navigate(-1);
          }
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
      setError(true);
      setErrorText(err?.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
    }
  };

  // #region SAVE COMPANY DATA
  const saveCompanyData = (companyObj, redirect) => {
    console.log(companyObj);
    let emptyFlag = true;
    for (const item in companyObj) {
      for (const [key, value] of Object.entries(companyObj[item])) {
        if (item !== "legal") {
          switch (item) {
            case "basic":
              emptyFlag = Object.hasOwn(companyObj[item], "shortName");
              emptyFlag = Object.hasOwn(companyObj[item], "longName");
              break;
            case "address":
              emptyFlag = Object.hasOwn(companyObj[item], "city");
              emptyFlag = Object.hasOwn(companyObj[item], "municipality");
              emptyFlag = Object.hasOwn(companyObj[item], "post");
              emptyFlag = Object.hasOwn(companyObj[item], "region");
              emptyFlag = Object.hasOwn(companyObj[item], "street");
              break;
            case "contact":
              emptyFlag = Object.hasOwn(companyObj[item], "email");
              emptyFlag = Object.hasOwn(companyObj[item], "phoneNumber");
              break;
            case "description":
              emptyFlag = Object.hasOwn(companyObj[item], "text");
              break;
            case "representitve":
              emptyFlag = Object.hasOwn(
                companyObj[item],
                "representativeEmail"
              );
              emptyFlag = Object.hasOwn(
                companyObj[item],
                "representativeLastName"
              );
              emptyFlag = Object.hasOwn(companyObj[item], "representativeName");
              break;
          }
        }
        if (key !== "bindingType") {
          // console.log("Val: " + value);
          if (value.length === 0) {
            setError(true);
            setErrorText(
              "Pri vaših podatkih manjka vrednost, preverite podatke in poskusite ponovno. Če menite, da gre za napako kontaktirajte našo podporo."
            );
            setErrorTitle("MANJKAJOČA VREDNOST");
          }
        }
      }

      console.log(emptyFlag);
    }
    setCompany(companyObj);
    setInvoiceRecieverStatus(companyObj.legal.invoiceReceiver);
    if (redirect) {
      companyObj.legal.invoiceReceiver ? setPageNumber(2) : setPageNumber(1);
    }
  };

  // #region SAVE INVOICE RECIEVER DATA
  const saveInvoiceRecieverData = (invoiceRecieverObj) => {
    setInvoiceReciever(invoiceRecieverObj);
    setPageNumber(2);
  };

  // #region USE EFFECT
  useEffect(() => {
    checkForLogin();
  }, []);

  useEffect(() => {
    console.log(company);
  }, [company]);

  // #region JSX
  return (
    <>
      {error && !isLoading && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}

      <Header />

      {pageNumber !== 3 && (
        <div className="company-login-button-box">
          <button onClick={() => setPageNumber(0)} className="button-4">
            Podjetje
          </button>
          {!invoiceRecieverStatus && (
            <button onClick={() => setPageNumber(1)} className="button-4">
              Prejemnik računa
            </button>
          )}
          <button onClick={() => setPageNumber(2)} className="button-4">
            Slike podjetja
          </button>
          <button onClick={() => loginCompany(true)} className="button-4">
            Ponudba podjetja
          </button>
          <button onClick={() => loginCompany(false)} className="button-4">
            Prijava podjetja
          </button>
        </div>
      )}

      {pageNumber === 0 && (
        <AddCompanyData company={company} savecompanydata={saveCompanyData} />
      )}
      {pageNumber === 1 && !invoiceRecieverStatus && (
        <UserInvoiceReciever
          handleinvoicerecieverwindow={handleInvoiceRecieverWindow}
          saveinvoicereciever={saveInvoiceRecieverData}
        />
      )}
      {pageNumber === 2 && (
        <AddCompanyImages formdata={formData.current} saveimages={saveImages} />
      )}

      {pageNumber === 3 && (
        <form onSubmit={submitOffer}>
          <div className="company-offer-form">
            <div className="add-icons-box">
              <div>
                <ProductIcon
                  className="add-product-icon"
                  onClick={() => {
                    setAddProductWindow(!addProductWindow);
                    setAddServiceWindow(false);
                    setIsCheckedReturn(false);
                    setIsCheckedWarranty(false);
                    setIsCheckedOrderItem(false);
                    setCharCountOffer(0);
                    setCharCountReturn(0);
                  }}
                />
                {addProductWindow && !addServiceWindow && (
                  <AddProduct
                    changeofferinput={changeOfferInput}
                    charcount={charCountOffer}
                    charcountreturn={charCountReturn}
                    imagename={imageNameOffer}
                    offertype="product"
                    addoffer={addOffer}
                    ischeckedwarranty={isCheckedWarranty}
                    ischeckedreturn={isCheckedReturn}
                    ischeckedorderitem={isCheckedOrderItem}
                    return={isCheckedReturn}
                    warranty={isCheckedWarranty}
                    closewindow={() => {
                      setAddProductWindow(!addProductWindow);
                      setAddServiceWindow(false);
                      setIsCheckedReturn(false);
                      setIsCheckedWarranty(false);
                      setIsCheckedOrderItem(false);
                      setCharCountOffer(0);
                      setCharCountReturn(0);
                    }}
                  />
                )}
              </div>
              <div>
                <ServiceIcon
                  className="add-service-icon"
                  onClick={() => {
                    setAddServiceWindow(!addServiceWindow);
                    setAddProductWindow(false);
                    setIsCheckedReturn(false);
                    setIsCheckedWarranty(false);
                    setCharCountOffer(0);
                    setCharCountReturn(0);
                  }}
                />
                {addServiceWindow && !addProductWindow && (
                  <AddService
                    changeofferinput={changeOfferInput}
                    charcount={charCountOffer}
                    imagename={imageNameOffer}
                    offertype="service"
                    addoffer={addOffer}
                    closewindow={() => {
                      setAddServiceWindow(!addServiceWindow);
                      setAddProductWindow(false);
                      setIsCheckedReturn(false);
                      setIsCheckedWarranty(false);
                      setCharCountOffer(0);
                    }}
                  />
                )}
              </div>
            </div>
            {companyOfferArr.product.length > 0 &&
              companyOfferArr.product.map((value, key) => (
                <div className="offer-list-item" key={key}>
                  <span>
                    <ProductIcon className="offer-list-icon" />
                  </span>
                  <span>{value.basic.name}</span>
                  <span>{offerCategoryParser(value.basic.category)}</span>
                  <span>{value.basic.type}</span>
                  <span>{value.basic.email}</span>
                  <span>{value.basic.price}</span>
                  <div className="button-box-offer-list">
                    <button
                      className="button-1"
                      type="button"
                      onClick={handleUpdateOfferWindow}
                    >
                      UREDI
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteOffer(value, "product")}
                      className="button-2"
                    >
                      Odstrani
                    </button>
                  </div>
                </div>
              ))}
            {companyOfferArr.service.length > 0 &&
              companyOfferArr.service.map((value, key) => (
                <div className="offer-list-item" key={key}>
                  <span>
                    <ServiceIcon className="offer-list-icon" />
                  </span>
                  <span>{value.basic.name}</span>
                  <span>{offerCategoryParser(value.basic.category)}</span>
                  <span>{value.basic.type}</span>
                  <span>{value.basic.email}</span>
                  <span>{value.basic.price}</span>
                  <div className="button-box-offer-list">
                    <button
                      className="button-1"
                      type="button"
                      onClick={handleUpdateOfferWindow}
                    >
                      UREDI
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteOffer(value, "service")}
                      className="button-2"
                    >
                      Odstrani
                    </button>
                  </div>
                  {isOpenUpdateOfferWindow && (
                    <UpdateOfferWindow
                      companyid={companyId}
                      offer={value}
                      offertype="service"
                      images={formData}
                      saveoffer={saveOffer}
                      handleupdateofferwindow={handleUpdateOfferWindow}
                    />
                  )}
                </div>
              ))}
            <input
              className="button-1"
              id="login-company-add-offer-btn"
              type="submit"
              value="Dodaj ponudbo"
            />
          </div>
        </form>
      )}
    </>
  );
};

export default CompanyLogin;
