import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InputBox from "../Components/InputBox";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../Constants/RegexPatterns";
import Loader from "../Components/Loader";
import ErrorWindow from "../Components/ErrorWindow";
import VerifyEmail from "../Components/VerifyEmail";
import forgottenPasswordAPI from "../Services/forgottenPassword";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";

import "../Assets/Styles/Pages/ForgottenPassword.css";
import { useNavigate } from "react-router-dom";

const ForgottenPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [openEnterCodeWindow, setOpenEnterCodeWindow] = useState(false);

  // #region handle Code window
  const handleCodeWindow = () => {
    setOpenEnterCodeWindow(!openEnterCodeWindow);
  };

  // #region handle Error window
  const handleErrorWindow = () => {
    setError(!error);
  };
  //  #region Handle input change
  const handleInputChange = (e, datatype) => {
    if (e.target.name === "password") {
      setPassword(e.target.value);
      return;
    }

    setEmail(e.target.value);
  };

  // #region submit password
  const submitForgotenPassword = async () => {
    if (!PASSWORD_REGEX.test(password)) {
      setError(true);
      setErrorText(
        "Geslo je v nepravilnem formatu vsebovati mora 1 veliko črko vsaj 8 znakov, vsaj 1 poseben znak in vsaj 1 številko"
      );
      setErrorTitle("GESLO UPORABNIKA");
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError(true);
      setErrorText(
        "E-naslov uporabnika je v napačnem formatu. Pravilen format: (janez.novak@odkrij.si)"
      );
      setErrorTitle("E-naslov uporabnika");

      return;
    }
    setIsLoading(true);
    try {
      const response = await forgottenPasswordAPI(password, email);

      if (response.data?.codeStatus === 1) {
        setIsLoading(false);
        setOpenEnterCodeWindow(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (!error.response) {
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setError(true);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response.data?.errorCode));
      }
    }
  };

  // #region JSX
  return (
    <>
      {isLoading && !error && <Loader />}

      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && !isLoading && openEnterCodeWindow && (
        <VerifyEmail
          email={email}
          handleverifyemailwindow={handleCodeWindow}
          changeemail={false}
          password={password}
        />
      )}
      <Header />
      <div className="forgotten-password-page">
        <div className="forgotten-password-form">
          <div className="forgotten-password-title">POZABLJENO GESLO</div>
          <InputBox
            label="E-naslov "
            placeholder="Vnesi e-naslov..."
            id="input-bar-1"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="email"
            datatype="basic"
            type="text"
            onchange={handleInputChange}
          />
          <InputBox
            label="Novo geslo "
            placeholder="Vnesi novo geslo..."
            id="input-bar-2"
            class="input-password"
            labelclass="label"
            inputcontainer="input-container-center"
            name="password"
            datatype="basic"
            type={showPassword ? "text" : "password"}
            showpassword={() => setShowPassword(!showPassword)}
            onchange={handleInputChange}
          />
          <div className="order-service-button-box">
            <button onClick={() => navigate(-1)} className="button-3">
              NAZAJ
            </button>
            <button onClick={submitForgotenPassword} className="button-1">
              SPREMENI
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgottenPassword;
