import { badWords } from "../../Constants/badWords";
import { NUMBER_REGEX, PRICE_REGEX } from "../../Constants/RegexPatterns";

const ValidateOfferArr = (offerArr) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";

  let offerType = undefined;
  for (const offer of offerArr) {
    offer === "service" ? (offerType = "STORITVE") : (offerType = "IZDELKA");
    for (const [key, value] of Object.entries(offer)) {
      switch (key) {
        case "ID":
          if (!NUMBER_REGEX.test(value)) {
            errorFound = true;
            errorText =
              "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
            errorTitle = `INDEX ${offerType}`;
          }
          break;
        case "companyId":
          if (!NUMBER_REGEX.test(value)) {
            errorFound = true;
            errorText =
              "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
            errorTitle = `INDEX PODJETJA ${offerType}`;
          }
          break;
        case "offerName":
          if (containsBadWord(value)) {
            errorFound = true;
            errorText =
              "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
            errorTitle = `IME ${offerType}`;
          }
          break;
        case "offerPrice":
          if (!PRICE_REGEX.test(value) && !NUMBER_REGEX.test(value)) {
            // console.log(value)
            errorFound = true;
            errorText =
              "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 00.000,00€";
            errorTitle = `CENA ${offerType}`;
          }
          break;
        case "offerType":
          if (containsBadWord(value)) {
            errorFound = true;
            errorText =
              "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
            errorTitle = `TIP ${offerType}`;
          }
          break;
        default:
          // console.log("Unknown input!" + key);
          errorFound = true;
          errorText = "Napačen vnos v obrazcu za odjavo ponudbe!";
          errorTitle = "NAPAČEN VNOS";
          break;
      }
    }
  }

  return { errorFound, errorTitle, errorText };
};

export default ValidateOfferArr;

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase())) {
      return true;
    }
  }

  return false;
};
