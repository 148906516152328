import React, { useEffect, useState } from "react";

import "../Assets/Styles/Pages/DisplayCompanies.css";
import { Link, useLocation } from "react-router-dom";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import getCompaniesData from "../Services/getCompaniesData";
import getPopularCompaniesArr from "../Services/getPopularCompaniesArr";
import ErrorWindow from "../Components/ErrorWindow";
import regionParser from "../Components/utils/RegionParser";

import "../Assets/Styles/Components/Loader.css";
import RegionFilter from "../Components/RegionFilter";
import CategoryFilter from "../Components/CategoryFilter";
import LegalFormFilter from "../Components/LegalFormFilter";
import LoaderSmall from "../Components/LoaderSmall";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

import { BsFilter as FilterIcon } from "react-icons/bs";

const DisplayCompanies = () => {
  const location = useLocation();
  const [popularCompaniesArr, setPopularCompaniesArr] = useState([]);
  const [companiesArr, setCompaniesArr] = useState([]);

  const [filters, setFilters] = useState({
    category: [],
    legalForm: [],
    region: [],
    query: "",
    page: 0,
  });

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [loadedData, setLoadedData] = useState(false);
  const [showPreviewId, setShowPreviewId] = useState(null);
  const [firstLoad, setFirstLoad] = useState(0);

  // #region Get companies
  const getCompanies = async () => {
    try {
      if (popularCompaniesArr.length === 0) {
        const res = await getPopularCompaniesArr(filters);
        const { popularCompanies } = res.data;
        setPopularCompaniesArr(popularCompanies);
      }

      const res = await getCompaniesData(filters);
      const { companies } = res.data;

      if (companiesArr.length === 0) {
        setCompaniesArr(companies);
      } else {
        setCompaniesArr([...companiesArr, ...companies]);
      }

      setLoadedData(true);
      setIsLoading(false);
    } catch (err) {
      // console.log(err)
      setError(true);
      setErrorText(err.response?.data?.message);
      setErrorTitle(ErrorCodesParser(err.response?.data?.errorCode));
    }
  };
  // #region change filters on query change
  useEffect(() => {
    setFilters({
      query: location.state?.query,
      page: 0,
    });

    setPopularCompaniesArr(location.state?.popularCompaniesArr);
    setCompaniesArr(location.state?.companiesArr);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.state?.query]);

  // #region Change filters on load
  useEffect(() => {
    const {
      category = [],
      legalForm = [],
      region = [],
      query = "",
      popularCompaniesArr = [],
      companiesArr = [],
    } = location.state || {};

    setFilters({
      category: category,
      legalForm: legalForm,
      region: region,
      query: query,
      page: 0,
    });

    setPopularCompaniesArr(popularCompaniesArr);
    setCompaniesArr(companiesArr);

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (firstLoad === 1 && filters.query.length === 0) {
      getCompanies();
      setFirstLoad(firstLoad + 1);
    }
    setFirstLoad(firstLoad + 1);
    setLoadedData(true);
  }, [filters]);

  //   #region page change
  useEffect(() => {
    if (filters.page > 0) {
      setIsLoading(true);
      getCompanies();
    }
  }, [filters.page]);

  // #region Handle scroll
  const handleScroll = () => {
    const windowValue = window.innerHeight;
    const scrollValue = document.documentElement.scrollTop;
    const pageHeight = document.documentElement.scrollHeight;
    if (Math.ceil(windowValue + scrollValue) >= pageHeight) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: prevFilters.page + 1,
      }));
      return;
    }

    if (Math.floor(windowValue + scrollValue) >= pageHeight) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: prevFilters.page + 1,
      }));
      return;
    }
  };

  // #region Check scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // #region Handle Filters
  const handleFilters = (value, name, checked) => {
    if (checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: [...prevFilters[name], value],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: prevFilters[name].filter((oldValue) => oldValue !== value),
      }));
    }
  };

  // #region Submit filter
  const submitFilters = () => {
    setPopularCompaniesArr([]);
    setCompaniesArr([]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: 0,
    }));
    setLoadedData(false);
    setShowFilterMenu(false);
  };

  // #region Loaded data
  useEffect(() => {
    if (!loadedData && firstLoad > 1) {
      setIsLoading(true);
      getCompanies();
    }
  }, [loadedData]);

  // #region HANDLE ERROR WINDOW
  const handleErrorWindow = () => {
    setError(false);
  };

  // #region HANDLE DEFAULT IMAGE
  const handleDefaultImage = (e) => {
    e.target.src = "/addImageIcon.svg";
  };

  // #region CLEAR FILTERS
  const clearFilters = () => {
    setFilters({
      category: [],
      legalForm: [],
      region: [],
      page: 0,
    });
  };

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  // #region JSX
  return (
    <div className="display-companies-page">
      <Header />
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      <div className="popular-companies-box">
        {popularCompaniesArr &&
          popularCompaniesArr.map((company) => (
            <Link
              key={company.ID}
              state={{
                ID: company.ID,
              }}
              to={`/podjetje/${company.shortName.replaceAll(" ", "_")}`}
              className="company-card"
              onMouseEnter={() => setShowPreviewId(company.ID)}
              onMouseLeave={() => setShowPreviewId(null)}
            >
              <img
                className="company-image"
                src={`${process.env.REACT_APP_COMPANY_IMAGES_URL}${company.ID}-${company.taxNumber}-logo.png`}
                alt={`Slika podjetja: ${company.shortName}`}
                loading="lazy"
                onError={(e) => handleDefaultImage(e)}
              />
              <div
                className="preview-info"
                style={{ opacity: showPreviewId === company.ID ? 1 : 0 }}
              >
                <span>Ime: {company.shortName}</span>
                <span>Davčna številka: {company.taxNumber}</span>
                <span>Email: {company.email}</span>
                <span>Matična številka: {company.registrationNumber}</span>
                <span>Regija: {regionParser(company.region)}</span>
                <span>TRR:{company.accountNumber}</span>
              </div>
            </Link>
          ))}
      </div>
      <div className="other-companies-box">
        <div className="filter-box-container">
          <FilterIcon
            onClick={() => setShowFilterMenu(!showFilterMenu)}
            className="filters-icon"
          />
          <div
            className={`filters-menu ${
              showFilterMenu ? "filters-menu-show" : ""
            }`}
          >
            <div className="button-box">
              <button onClick={submitFilters} className="button-1">
                POTRDI
              </button>
              {/* <button onClick={clearFilters} className="button-2">
              POČISTI
            </button> */}
            </div>
            <RegionFilter index={1} handlefilters={handleFilters} />
            <CategoryFilter index={100} handlefilters={handleFilters} />
            <LegalFormFilter index={200} handlefilters={handleFilters} />
          </div>
        </div>
        <div className="companies-box">
          {companiesArr &&
            companiesArr.map((company, key) => (
              <Link
                key={company.ID}
                state={{
                  ID: company.ID,
                }}
                to={`/podjetje/${company.shortName.replaceAll(" ", "_")}`}
                className="company-card"
                onMouseEnter={() => setShowPreviewId(company.ID)}
                onMouseLeave={() => setShowPreviewId(null)}
              >
                <img
                  src={`${process.env.REACT_APP_COMPANY_IMAGES_URL}${company.ID}-${company.taxNumber}-logo.png`}
                  alt={`Slika podjetja: ${company.shortName}`}
                  loading="lazy"
                  onError={(e) => handleDefaultImage(e)}
                />
                <div
                  className="preview-info"
                  style={{ opacity: showPreviewId === company.ID ? 1 : 0 }}
                >
                  <span>Ime:{company.shortName}</span>
                  <span>Davčna številka: {company.taxNumber}</span>
                  <span>E-naslov:{company.email}</span>
                  <span>Matična številka: {company.registrationNumber}</span>
                  <span>Regija: {regionParser(company.region)}</span>
                  <span>TRR:{company.accountNumber}</span>
                </div>
              </Link>
            ))}
        </div>
      </div>
      {isLoading && <LoaderSmall />}
      <Footer />
    </div>
  );
};

export default DisplayCompanies;
