import React, { useEffect, useRef, useState } from "react";
import ErrorWindow from "./ErrorWindow";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import ImageInput from "./ImageInput";

import "../Assets/Styles/Components/AddCompanyImages.css";
import WarningWindow from "./WarningWindow";

const AddCompanyImages = (props) => {
  // #region VARIABLES
  const [imageName, setImageName] = useState(undefined);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [flagsObj, setFlagsObj] = useState({
    intro: false,
    logo: false,
    productsImg: false,
    showWarning: false,
    service: false,
    product: false,
  });

  const [previewImagesObj, setPreviewImagesObj] = useState({
    logo: null,
    intro: null,
    productsImg: null,
  });

  const [logoImg, setLogoImg] = useState(false);
  const [introImg, setIntroImg] = useState(false);
  const [productsImg, setProductsImg] = useState(false);

  const formData = useRef(new FormData());

  const navigate = useNavigate();

  const [companyId, setCompanyId] = useState(undefined);

  const [pageNumber, setPageNumber] = useState(0);

  // #region HANDLE WINDOWS
  // #region ERROR
  const handleErrorWindow = () => {
    setError(false);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
  };

  // #region CHANGE IMAGES
  const changeInput = (e, datatype) => {
    const type = e.name.split("-");
    if (flagsObj[type[type.length - 1]]) {
      let tempArr = formData.current.getAll("images");

      formData.current.delete("images");

      if (type[type.length - 1] === "intro") {
        tempArr[1] = e;
      }

      if (type[type.length - 1] === "logo") {
        tempArr[0] = e;
      }

      if (type[type.length - 1] === "productsImg") {
        tempArr[2] = e;
      }

      for (const item of tempArr) {
        formData.current.append("images", item);
      }
    } else {
      setFlagsObj({ ...flagsObj, [type[type.length - 1]]: true });
      formData.current.append("images", e);
    }
  };

  // #region SAVE COMPANY IMAGE
  const submitImages = async () => {
    setIsLoading(true);
    // if (!imageName) {
    //   setError(true);
    //   return;
    // }
    // if (formData.current.getAll("images").length !== 3) {
    //   setIsLoading(false);
    //   setError(true);
    //   setErrorText(
    //     "V obrazcu ste pozabili vnesti slike podjetja preverite podatke ali kontaktirajte našo podporno ekipo."
    //   );
    //   setErrorTitle("MANJKAJO SLIKE PODJETJA");
    //   return;
    // }

    props.saveimages(formData.current);

    setIsLoading(false);
  };

  // #region

  // #region USE EFFECT
  useEffect(() => {
    if (flagsObj.intro && flagsObj.logo && flagsObj.productsImg) {
      setFlagsObj({
        ...flagsObj,
        showWarning: true,
      });
    }
  }, [flagsObj.intro, flagsObj.logo, flagsObj.productsImg]);

  useEffect(() => {
    const imagesArr = props.formdata.getAll("images");

    const objUrlArr = [];
    console.log(imagesArr);
    for (const image of imagesArr) {
      const imageType = image.name.split("-").pop();

      setFlagsObj((prev) => ({
        ...prev,
        [imageType]: true,
      }));
      const objUrl = URL.createObjectURL(image);
      setPreviewImagesObj((prev) => ({
        ...prev,
        [imageType]: objUrl,
      }));
      objUrlArr.push(objUrl);
    }

    return () => {
      objUrlArr.forEach((objUrl) => {
        URL.revokeObjectURL(objUrl);
      });
    };
  }, [props.formdata]);

  // useEffect(() => {
  //   console.log(previewImagesObj);
  // }, [previewImagesObj]);
  return (
    <>
      {error && !isLoading && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}

      <div className="add-company-images-button-box">
        <button onClick={() => setPageNumber(0)} className="button-3">
          Logotip
        </button>
        <button onClick={() => setPageNumber(1)} className="button-3">
          Začetna stran
        </button>
        <button onClick={() => setPageNumber(2)} className="button-3">
          Ponudba
        </button>
      </div>

      {!error && !isLoading && (
        <div className="company-images-form">
          {pageNumber === 0 && (
            <div className="image-box">
              <ImageInput
                datatype="image"
                name={`${imageName}-logo`}
                src={previewImagesObj.logo ? previewImagesObj.logo : ""}
                classname="loading-image"
                title="Logotip podjetja"
                id="input-img-1"
                onchange={changeInput}
                alt="Logotip podjetja"
                nextbtn="Naprej"
                nextimg={() => {
                  setPageNumber(1);
                }}
                height="70%"
                width="45%"
              />
            </div>
          )}

          {pageNumber === 1 && (
            <div className="image-box">
              <ImageInput
                datatype="image"
                name={`${imageName}-intro`}
                src={previewImagesObj.intro ? previewImagesObj.intro : ""}
                classname="loading-image"
                id="input-img-2"
                onchange={changeInput}
                alt="Začetna slika podjetja"
                nextbtn="Naprej"
                title="Slika na začetni strani"
                backbtn="Nazaj"
                backimg={() => {
                  setPageNumber(0);
                }}
                nextimg={() => {
                  setPageNumber(2);
                }}
                height="70vh"
                width="70%"
              />
            </div>
          )}

          {pageNumber === 2 && (
            <div className="image-box">
              <ImageInput
                datatype="image"
                name={`${imageName}-productsImg`}
                src={
                  previewImagesObj.productsImg
                    ? previewImagesObj.productsImg
                    : ""
                }
                title="Slika na strani ponudbe"
                classname="loading-image"
                id="input-img-3"
                onchange={changeInput}
                alt="Slika ponudbe podjetja"
                backbtn="Nazaj"
                nextbtn="Shrani"
                backimg={() => {
                  setPageNumber(1);
                }}
                nextimg={() => {}}
                height="50vh"
                width="45%"
              />
            </div>
          )}
          {flagsObj.showWarning && (
            <WarningWindow
              title="VSE SLIKE DODANE"
              text='Dodali ste vse potrebne slike, če ste zadovoljni z izbiro stisnite "Shrani", če bi karkoli spremenili pritisnite "Spremeni"'
              gobackbtn="Spremeni"
              proceedbtn="Shrani"
              goback={() => {
                setFlagsObj({ ...flagsObj, showWarning: false });
              }}
              proceed={submitImages}
            />
          )}

          {flagsObj.intro && flagsObj.logo && flagsObj.productsImg && (
            <div className="button-box">
              <button className="button-1" onClick={submitImages}>
                Shrani slike
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AddCompanyImages;
