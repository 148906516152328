import { NUMBER_REGEX } from "../../Constants/RegexPatterns";

const regionParser = (regionKey) => {
  switch (NUMBER_REGEX.test(regionKey) ? parseInt(regionKey) : regionKey) {
    case 1:
      return "Gorenjska regija";
    case 2:
      return "Goriška regija";
    case 3:
      return "Jugovzhodna slovenija";
    case 4:
      return "Koroška regija";
    case 5:
      return "Obalno-kraška regija";
    case 6:
      return "Osrednjeslovenska regija";
    case 7:
      return "Podravska regija";
    case 8:
      return "Pomurska regija";
    case 9:
      return "Posavska regija";
    case 10:
      return "Primorsko-notranjska regija";
    case 11:
      return "Savinjska regija";
    case 12:
      return "Zasavska regija";
    case "regija":
      return "regija";
    default:
      return "Regija ne obstaja";
  }
};

export default regionParser;
