import React from "react";
import InputBox from "./InputBox";
import TextArea from "./TextArea";
import DropDownMenu from "./DropDownMenu";
import CheckBox from "./CheckBox";
import ImageInput from "./ImageInput";

import { BsXLg as CloseIcon } from "react-icons/bs";

import "../Assets/Styles/Components/AddProduct.css";

const AddProduct = (props) => {
  return (
    <div className="add-product-page">
      <div className="add-product-box">
        <span>
          <CloseIcon
            onClick={() => {
              if (props.closewindow) {
                props.closewindow();
              } else {
                props.goback();
              }
            }}
            className="close-icon"
          />
        </span>
        <InputBox
          label="*Ime izdelka:"
          id="input-bar-1"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="name"
          datatype="basic"
          type="text"
          placeholder="Vnesi ime izdelka..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="*Cena izdelka:"
          id="input-bar-2"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="price"
          datatype="basic"
          type="text"
          placeholder="Vnesi ceno (vključi tudi poštnino)..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="*E-naslov:"
          id="input-bar-3"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="email"
          datatype="basic"
          type="text"
          placeholder="Vnesi e-naslov za kontakt o informacijah o izdelku..."
          onchange={props.changeofferinput}
        />
        <CheckBox
          label="Garancija izdelka:"
          id="checkbox-1"
          name="warranty"
          value="true"
          datatype="flags"
          labelclass="label"
          ischecked={props.ischeckedwarranty}
          onchange={props.changeofferinput}
        />
        {props.warranty && (
          <InputBox
            label="*Čas garancije"
            id="input-bar-7"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="warrantyTime"
            datatype="basic"
            type="text"
            placeholder="Vnesi čas kako dolgo traja garancija izdelka..."
            onchange={props.changeofferinput}
          />
        )}
        <DropDownMenu
          label="*Kategorija izdelka"
          placeholder="kategorija"
          id="dropdown-1"
          dropdownbox="dropdown-box-center"
          name="category"
          datatype="basic"
          onchange={props.changeofferinput}
          data={[
            { value: 1, text: "Elektronika" },
            { value: 2, text: "Računalništvo" },
            { value: 3, text: "Gospodinjski aparati" },
            { value: 4, text: "Oblačila" },
            { value: 5, text: "Lepota in zdravje" },
            { value: 6, text: "Šport" },
            { value: 7, text: "Igrače" },
            { value: 8, text: "Dom" },
            { value: 9, text: "Vrt" },
            { value: 10, text: "Hrana in pijača" },
            { value: 11, text: "Avtomobilizem" },
            { value: 12, text: "Knjige" },
            { value: 13, text: "Glasba in filmi" },
            { value: 14, text: "Pisarna in šola" },
            { value: 15, text: "Bela tehnika" },
            { value: 16, text: "Orodja" },
            { value: 17, text: "Strojni deli in komponente" },
            { value: 18, text: "Gradbeni material" },
            {
              value: 19,
              text: "Indostrijski material (les, kovina, plastika...)",
            },
            { value: 20, text: "Leseni material in izdelki" },
          ]}
        />
        <InputBox
          label="*Vrsta izdelka:"
          id="input-bar-4"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="type"
          datatype="basic"
          type="text"
          placeholder="Vnesi vrsto izdelka..."
          onchange={props.changeofferinput}
        />
        <InputBox
          label="*Čas dostave izdelka:"
          id="input-bar-5"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="time"
          datatype="basic"
          type="text"
          placeholder="Vnesi čas dostave izdelka..."
          onchange={props.changeofferinput}
        />
        <CheckBox
          label="Vračilo izdelka:"
          id="checkbox-2"
          name="return"
          labelclass="label"
          value="true"
          datatype="flags"
          ischecked={props.ischeckedreturn}
          onchange={props.changeofferinput}
        />
        {props.return && (
          <TextArea
            label="*Pogoji za vračilo"
            id="textarea-1"
            name="returnConditions"
            rows="5"
            cols="25"
            placeholder="Vnesi pogoje za vračilo izdelka..."
            datatype="basic"
            char={props.charcountreturn}
            minChar="200"
            labelclass="label"
            onchange={props.changeofferinput}
          />
        )}
        <TextArea
          label="*Opis izdelka"
          id="textarea-2"
          name="text"
          rows="5"
          cols="25"
          placeholder="Vnesi opis izdelka..."
          datatype="basic"
          char={props.charcount}
          minChar="200"
          labelclass="label"
          onchange={props.changeofferinput}
        />
        <CheckBox
          label="Naročilo izdelka:"
          id="checkbox-3"
          name="orderItem"
          labelclass="label"
          value="true"
          datatype="flags"
          ischecked={props.ischeckedorderitem}
          onchange={props.changeofferinput}
        />
        {props.ischeckedorderitem && (
          <InputBox
            label="*Količina:"
            id="input-bar-8"
            class="input-bar"
            labelclass="label"
            inputcontainer="input-container-center"
            name="quantity"
            datatype="basic"
            type="text"
            placeholder="Vnesi količino izdelkov..."
            onchange={props.changeofferinput}
          />
        )}
        {props.imagename && (
          <div className="add-product-image-box">
            <ImageInput
              datatype="image"
              name={`${props.imagename}-product`}
              id="input-img-1"
              src=""
              classname="loading-image"
              title="Slika izdelka (slika ni v velikosti kot bo na strani)"
              onchange={props.changeofferinput}
              alt="Slika izdelka podjetja"
              width="80%"
              height="250px"
              nextbtn="Shrani sliko"
              nextimg={true}
            />
          </div>
        )}
        <button
          className="button-1"
          type="button"
          onClick={() => {
            props.addoffer(props.offertype);
            if (props.closewindow) {
              props.closewindow();
            } else {
              props.goback();
            }
          }}
        >
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default AddProduct;
