import {
  EMAIL_REGEX,
  HOW_LONG_REGEX,
  NUMBER_REGEX,
  PRICE_REGEX,
} from "../../Constants/RegexPatterns";
import { badWords } from "../../Constants/badWords";
const ValidateSingleOffer = (offer) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";

  let offerType = undefined;

  offerType = offer.basic.offerType === "service" ? "STORITVE" : "PRODUKTA";
  // console.log(offer);
  for (const attributeOfOffer in offer) {
    for (const [key, value] of Object.entries(offer[attributeOfOffer])) {
      switch (attributeOfOffer) {
        case "basic":
          switch (key) {
            case "name":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = `IME ${offerType}`;
              }
              break;
            case "price":
              if (!PRICE_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 00.000,00€";
                errorTitle = `CENA ${offerType}`;
              }
              break;
            case "email":
              if (!EMAIL_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: ime.priimek@odkrij.si";
                errorTitle = `E-NASLOV ${offerType}`;
              }
              break;
            case "warrantyTime":
              if (value && !HOW_LONG_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0 dni";
                errorTitle = `ČAS GARANCIJE ${offerType}`;
              }
              break;
            case "category":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = `KATEGORIJA ${offerType}`;
              }
              break;
            case "type":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = `VRSTA ${offerType}`;
              }
              break;
            case "time":
              if (!HOW_LONG_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0 dni";
                errorTitle = `ČAS ${offerType}`;
              }
              break;
            case "returnConditions":
              if (value && containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = `POGOJI ZA VRAČILO ${offerType}`;
              }
              break;
            case "text":
              if (containsBadWord(value)) {
                errorFound = true;
                errorText =
                  "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                errorTitle = `OPIS ${offerType}`;
              }
              break;
            case "ID":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = `ID ${offerType}`;
              }
              break;
            case "companyId":
              if (!NUMBER_REGEX.test(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                errorTitle = `ID PODJETJA ${offerType}`;
              }
              break;
            case "offerType":
              if (value !== "service" && value !== "product") {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: service/product";
                errorTitle = `VRSTA PONUDBE ${offerType}`;
              }
              break;
            default:
              // console.log(key + " " + value);
              errorFound = true;
              errorText = `Napačen vnos v razdelku osnovni podatki!`;
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        case "flags":
          switch (key) {
            case "returnItem":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "VRAČILO IZDELKA";
              }
              break;
            case "warranty":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "GARANCIJA IZDELKA";
              }
              break;
            default:
              errorFound = true;
              errorText = errorText = "Napačen vnos v razdelku zastavice!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        case "bool":
          switch (key) {
            case "returnItem":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "VRAČILO IZDELKA";
              }
              break;
            case "warranty":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "GARANCIJA IZDELKA";
              }
              break;
            case "orderItem":
              if (!isBoolean(value)) {
                errorFound = true;
                errorText =
                  "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                errorTitle = "NAROČILO IZDELKA";
              }
              break;
            default:
              // console.log(key);
              // console.log(value);
              errorFound = true;
              errorText = errorText = "Napačen vnos v razdelku zastavice!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
          break;
        default:
          errorFound = true;
          errorText = "Napačen vnos v obrazcu za urejanje ponudbe!";
          errorTitle = "NAPAČEN VNOS";
          break;
      }
    }
  }
  return { errorFound, errorText, errorTitle };
};

export default ValidateSingleOffer;

const isBoolean = (value) => {
  return value === true || value === false || value === 0 || value === 1;
};

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase())) {
      return true;
    }
  }

  return false;
};
