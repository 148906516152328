import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorWindow from "./ErrorWindow";
import Loader from "./Loader";
import InputBox from "./InputBox";
import validateCompanyData from "./utils/ValidateCompanyData";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import DropDownMenu from "./DropDownMenu";

import "../Assets/Styles/Components/AddCompanyData.css";
import legalFromParser from "./utils/LegalFormParser";
import categoryParser from "./utils/CategoryParser";
import regionParser from "./utils/RegionParser";

const AddCompanyData = (props) => {
  // #region VARIABLES
  const [company, setCompany] = useState({
    legal: {
      taxPayer: false,
      invoiceReceiver: false,
      binding: false,
      bindingType: null,
    },
  });

  const [flags, setFlags] = useState({
    binding: false,
    invoiceReceiver: false,
    taxPayer: false,
  });

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const [invoiceRecieverStatus, setInvoiceRecieverStatus] = useState(true);

  // #region CHANGE INPUT
  const changeInput = (e, datatype) => {
    if (
      e.target.name === "invoiceReceiver" ||
      e.target.name === "taxPayer" ||
      e.target.name === "binding"
    ) {
      setCompany({
        ...company,
        [datatype]: {
          ...company[datatype],
          [e.target.name]: e.target.checked,
        },
      });

      setFlags({
        ...flags,
        [e.target.name]: true,
      });
    } else {
      setCompany({
        ...company,
        [datatype]: {
          ...company[datatype],
          [e.target.name]: e.target.value,
        },
      });
      if (datatype === "description") {
        setCharCount(e.target.value.length);
      }
    }
  };

  // #region HANDLE WINDOWS
  // #region ERROR WINDOW
  const handleErrorWindow = () => {
    setError(false);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
  };

  const saveCompanyData = () => {
    setIsLoading(true);

    const { errorFound, errorText, errorTitle } = validateCompanyData(company);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    if(errorFound){
      props.savecompanydata(company, false);
      setIsLoading(false);
    } else {
      props.savecompanydata(company, true);
      setIsLoading(false);
    }
  };

  // #region USE EFFECT
  useEffect(() => {
    console.log(props.company);
    if (props.company) {
      setCompany(props.company);
    }
  }, []);
  //   useEffect(() => {
  //     console.log(company);
  //   }, [company]);

  //   useEffect(() => {
  //     if (charCount > 1000) {
  //       console.log("ERROR:");
  //     }
  //   }, [charCount]);

  return (
    <>
      {error && !isLoading && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {!error && isLoading && <Loader />}

      {!error && !isLoading && (
        <div className="company-data-form">
          <div className="grid-item" id="grid-item-1">
            <InputBox
              label="*Kratko ime: "
              id="input-bar-1"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="shortName"
              datatype="basic"
              placeholder="Vnesi kratko ime..."
              defaultvalue={
                props.company ? props.company?.basic?.shortName : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Popolno ime: "
              id="input-bar-2"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="longName"
              datatype="basic"
              placeholder="Vnesi popolno ime..."
              defaultvalue={
                props.company ? props.company?.basic?.longName : null
              }
              type="text"
              onchange={changeInput}
            />
          </div>
          <div className="grid-item" id="grid-item-2">
            <InputBox
              label="*Telefonska številka: "
              id="input-bar-10"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="phoneNumber"
              datatype="contact"
              placeholder="Vnesi telefonsko številko..."
              defaultvalue={
                props.company ? props.company?.contact?.phoneNumber : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*E-naslov: "
              id="input-bar-11"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="email"
              datatype="contact"
              placeholder="Vnesi E-naslov..."
              defaultvalue={
                props.company ? props.company?.contact?.email : null
              }
              type="text"
              onchange={changeInput}
            />
          </div>
          <div className="grid-item" id="grid-item-3">
            <DropDownMenu
              label="*Pravna oblika:"
              placeholder={
                props.company
                  ? legalFromParser(
                      props.company?.legal?.legalForm || "*pravna oblika"
                    )
                  : "*pravna oblika"
              }
              id="input"
              dropdownbox="dropdown-box"
              name="legalForm"
              datatype="legal"
              onchange={changeInput}
              data={[
                { value: 1, text: "Samostojni podjetnik (s.p.)" },
                { value: 2, text: "Družba z omejeno odgovornostjo (d.o.o.)" },
                {
                  value: 3,
                  text: "Družba z neomejeno odgovornostjo (d.n.o.)",
                },
                { value: 4, text: "Komanditna družba (k.d.)" },
                { value: 5, text: "Delniška družba (d.d.)" },
                { value: 6, text: "Komanditna delniška družba (k.d.d.)" },
                { value: 7, text: "Podružnica" },
                { value: 8, text: "Gospodarsko interesno združenje" },
                { value: 9, text: "Socialno podjetništvo" },
                { value: 10, text: "Invalidsko podjetje" },
                { value: 11, text: "Zavod" },
                { value: 12, text: "Društvo" },
                { value: 13, text: "Ustanova" },
                { value: 14, text: "Kmetijsko gospodarstvo" },
                { value: 15, text: "Mladinska organizacija" },
                { value: 16, text: "Status kmeta" },
                { value: 17, text: "Zadruga" },
                { value: 18, text: "Status samostojnega novinarja" },
                { value: 19, text: "Status samozaposlenega v kulturi" },
                { value: 20, text: "Status zasebnega raziskovalca" },
                { value: 21, text: "Kratkotrajno delo" },
                { value: 22, text: "Osebno dopolnilno delo" },
                { value: 23, text: "Začasno ali občasno delo upokojencev" },
                { value: 24, text: "Sobodajalstvo" },
              ]}
            />
            <InputBox
              label="*Matična številka: "
              id="input-bar-7"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              datatype="legal"
              name="registrationNumber"
              placeholder="Vnesi matično številko..."
              defaultvalue={
                props.company ? props.company?.legal?.registrationNumber : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Davčna številka: "
              id="input-bar-8"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="taxNumber"
              datatype="legal"
              placeholder="Vnesi davčno številko..."
              defaultvalue={
                props.company ? props.company?.legal?.taxNumber : null
              }
              type="text"
              onchange={changeInput}
            />
            <CheckBox
              label="Davčni zavezanec "
              id="checkbox-1"
              labelclass="label"
              datatype="legal"
              name="taxPayer"
              ischecked={
                flags.taxPayer || !props.company
                  ? company.legal?.taxPayer
                  : props.company?.legal?.taxPayer
              }
              value="true"
              onchange={changeInput}
            />
            <InputBox
              label="*Številka računa (TRR): "
              id="input-bar-9"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="accountNumber"
              datatype="legal"
              placeholder="Vnesi številko računa (TRR)..."
              defaultvalue={
                props.company ? props.company?.legal?.accountNumber : null
              }
              type="text"
              onchange={changeInput}
            />
            <DropDownMenu
              label="*Kategorija podjetja:"
              placeholder={
                props.company
                  ? categoryParser(
                      props.company?.legal?.category || "*kategorija"
                    )
                  : "*kategorija"
              }
              id="input-2"
              dropdownbox="dropdown-box"
              name="category"
              datatype="legal"
              onchange={changeInput}
              data={[
                { value: 1, text: "Energetika" },
                { value: 2, text: "Gradbeništvo" },
                { value: 3, text: "Nepremičnine" },
                { value: 4, text: "Proizvodnja" },
                { value: 5, text: "Izobraževanje" },
                { value: 6, text: "Kmetijstvo, gozdarstvo in ribištvo" },
                { value: 7, text: "Kultura" },
                { value: 8, text: "Transport" },
                { value: 9, text: "Turizem in gostinstvo" },
                { value: 10, text: "Trgovina in storitve" },
                { value: 11, text: "Finančne storitve" },
                { value: 12, text: "Tehnologija in informacijske storitve" },
                { value: 13, text: "Industrija" },
                { value: 14, text: "Poslovne storitve" },
              ]}
            />
            <CheckBox
              label="Račun izstavljen podjetju"
              id="checkbox-2"
              labelclass="label"
              datatype="legal"
              name="invoiceReceiver"
              ischecked={
                flags.invoiceReceiver || !props.company
                  ? company.legal?.invoiceReceiver
                  : props.company?.legal?.invoiceReceiver
              }
              value="true"
              onchange={changeInput}
            />
          </div>
          <div className="grid-item" id="grid-item-4">
            <InputBox
              label="*Ulica: "
              id="input-bar-3"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="street"
              datatype="address"
              placeholder="Vnesi ulico podjetja..."
              defaultvalue={
                props.company ? props.company?.address?.street : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Kraj: "
              id="input-bar-4"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="city"
              datatype="address"
              placeholder="Vnesi kraj podjetja..."
              defaultvalue={props.company ? props.company?.address?.city : null}
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Pošta: "
              id="input-bar-5"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="post"
              datatype="address"
              placeholder="Vnesi pošto podjetja..."
              defaultvalue={props.company ? props.company?.address?.post : null}
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Občina: "
              id="input-bar-6"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="municipality"
              datatype="address"
              placeholder="Vnesi občino podjetja..."
              defaultvalue={
                props.company ? props.company?.address?.municipality : null
              }
              type="text"
              onchange={changeInput}
            />
            <DropDownMenu
              label="*Regija:"
              id="input-1"
              dropdownbox="dropdown-box"
              name="region"
              datatype="address"
              onchange={changeInput}
              placeholder={
                props.company
                  ? regionParser(props.company?.address?.region || "*regija")
                  : "*regija"
              }
              data={[
                { value: 1, text: "Gorenjska regija" },
                { value: 2, text: "Goriška regija" },
                { value: 3, text: "Jugovzhodna slovenija" },
                { value: 4, text: "Koroška regija" },
                { value: 5, text: "Obalno-kraška regija" },
                { value: 6, text: "Osrednjeslovenska regija" },
                { value: 7, text: "Podravska regija" },
                { value: 8, text: "Pomurska regija" },
                { value: 9, text: "Posavska regija" },
                { value: 10, text: "Primorsko-notranjska regija" },
                { value: 11, text: "Savinjska regija" },
                { value: 12, text: "Zasavska regija" },
              ]}
            />
          </div>
          <div className="grid-item" id="grid-item-5">
            <InputBox
              label="*Ime predstavnika: "
              id="input-bar-12"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="representativeName"
              datatype="representative"
              placeholder="Vnesi ime..."
              defaultvalue={
                props.company
                  ? props.company?.representative?.representativeName
                  : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*Priimek predstavnika: "
              id="input-bar-13"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="representativeLastName"
              datatype="representative"
              placeholder="Vnesi priimek..."
              defaultvalue={
                props.company
                  ? props.company?.representative?.representativeLastName
                  : null
              }
              type="text"
              onchange={changeInput}
            />
            <InputBox
              label="*E-naslov predstavnika: "
              id="input-bar-14"
              class="input-bar"
              labelclass="label"
              inputcontainer="input-container"
              name="representativeEmail"
              datatype="representative"
              placeholder="Vnesi E-naslov..."
              defaultvalue={
                props.company
                  ? props.company?.representative?.representativeEmail
                  : null
              }
              type="text"
              onchange={changeInput}
            />
            <CheckBox
              label="Vezava (24 mesecev -20%) "
              id="checkbox-3"
              labelclass="label"
              datatype="legal"
              name="binding"
              ischecked={
                flags.binding || !props.company
                  ? company.legal?.binding
                  : props.company?.legal?.binding
              }
              value="true"
              onchange={changeInput}
            />

            {company.legal.binding && (
              <InputBox
                label="*Kupon: "
                id="input-bar-15"
                class="input-bar"
                labelclass="label"
                inputcontainer="input-container"
                name="bindingType"
                datatype="legal"
                placeholder="Vnesi kupon..."
                defaultvalue={
                  props.company ? props.company?.legal?.bindingType : null
                }
                type="text"
                onchange={changeInput}
              />
            )}
          </div>
          <div className="grid-item" id="grid-item-6">
            <TextArea
              label="*Opis podjetja"
              id="textarea"
              name="text"
              rows="10"
              cols="50"
              placeholder="Vnesi opis podjetja..."
              defaultvalue={
                props.company ? props.company?.description?.text : null
              }
              datatype="description"
              char={charCount}
              minChar="1000"
              labelclass="label"
              onchange={changeInput}
            />
          </div>
        </div>
      )}
      <div className="add-company-data-button-box">
        <input
          type="submit"
          className="button-1"
          onClick={saveCompanyData}
          value="Shrani"
        />
      </div>
    </>
  );
};

export default AddCompanyData;
