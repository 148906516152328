import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Assets/Styles/Pages/GetOffers.css";

const GetOffers = () => {
  return (
    <>
      <Header />
      <div className="get-offers-page">
        <div className="get-offers-text-box">
          <span className="privacy-policy-title">Pridobivanje ponudbe: </span>
          <span className="text">
            Z izbiro možnosti »Dobi ponudbe« potrjujete, da soglašate z obdelavo
            svojih osebnih podatkov za namen pošiljanja prilagojenih ponudb,
            novic in informacij, ki so povezane z novimi podjetji, storitvami
            ter novostmi na področjih, ki vas zanimajo. Vaši osebni podatki bodo
            obdelani v skladu z veljavnimi predpisi o varstvu podatkov, vključno
            z evropsko Splošno uredbo o varstvu podatkov (GDPR) in lokalnimi
            zakonodajnimi zahtevami. Obdelava podatkov poteka izključno za
            zgoraj navedene namene, pri čemer zagotavljamo, da so vaši podatki
            varni in zaščiteni pred nepooblaščenim dostopom ali zlorabo. Vsako
            sporočilo, ki ga prejmete, vključuje povezavo za odjavo, ki vam
            omogoča enostavno in hitro odjavo od nadaljnjega prejemanja
            obvestil. Po odjavi vaših podatkov ne bomo več uporabljali za ta
            namen. Vedno imate pravico zahtevati vpogled v svoje podatke, njihov
            popravek, izbris ali prenos. Naše podjetje spoštuje vašo zasebnost
            in zagotavlja varno ter odgovorno obdelavo podatkov. Za dodatne
            informacije in uveljavitev vaših pravic nas lahko kontaktirate na
            naslovu: 
            <a className="mail-link" href="mailto:info@odkrij.si">
              info@odkrij.si
            </a>
          </span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetOffers;
