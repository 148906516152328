import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorWindow from "../Components/ErrorWindow";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import Offer from "./Offer";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import Header from "../Components/Header";
import getOfferData from "../Services/getOfferData";

const OfferViaUrl = (props) => {
  const location = useLocation();

  const [offer, setOffer] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  //   #region Use Effect
  useEffect(() => {
    if (!location?.state?.basic) {
      // console.log(location.pathname.split("/"));
      const pathArr = location.pathname.split("/");
      const offerName = pathArr[pathArr.length - 1];

      getOffer(offerName);
    } else {
      setOffer(location.state);
    }
  }, []);



  //   #region GET OFFER
  const getOffer = async (offerName) => {
    try {
      setIsLoading(true);

      const response = await getOfferData(offerName);

      if (response.status === 200) {
        // console.log(response)
        setIsLoading(false);
        setOffer(response?.data?.offer);
      } else {
        setIsLoading(false);
        setError(true);
        setErrorText(
          "Pri pošiljanju podatkov je prišlo do napake poskusite kasneje ali kontaktirajte našo podporno ekipo."
        );
        setErrorTitle("NAPAKA");
      }
    } catch (error) {
      setIsLoading(false);
      setError(true);
      if (!error.response) {
        setIsLoading(false);
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setIsLoading(false);
        setErrorText(error?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(error?.response?.data?.errorCode));
      }
    } finally {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIsLoading(false);
    }
  };

  const handleErrorWindow = () => {
    setError(!error);
    setErrorText("");
    setErrorTitle("");
  };

  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      <Header />

      {isLoading && !offer && !error && <Loader />}

      {offer && <Offer companyid={offer.basic.companyId} offer={offer} />}

      <Footer />
    </>
  );
};

export default OfferViaUrl;
