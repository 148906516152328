import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../Assets/Styles/Pages/CompanyAdministration.css";

import { ErrorCodesParser } from "../Constants/ErrorCodesParser";

import Company from "../Pages/Company";
import Loader from "../Components/Loader";
import ErrorWindow from "../Components/ErrorWindow";
import UpdateCompanyWindow from "../Components/UpdateCompanyWindow";
import validateCompanyData from "../Components/utils/ValidateCompanyData";
import DeleteCompanyWindow from "../Components/DeleteCompanyWindow";
import Header from "../Components/Header";

import getCompanyAdministrationData from "../Services/getCompanyAdministrationData";
import getCompanyData from "../Services/getCompanyData";
import updateCompanyData from "../Services/updateCompanyData";
import insertCompanyImages from "../Services/insertCompanyImages";
import renameCompanyImages from "../Services/renameCompanyImages";

import { BsXLg as CloseIcon } from "react-icons/bs";
import WarningWindow from "../Components/WarningWindow";

const CompanyAdministration = () => {
  const navigate = useNavigate();

  const [company, setCompany] = useState(null);

  const [userId, setUserId] = useState("");

  const [companiesArray, setCompaniesArray] = useState({
    companiesIds: [],
    current: 0,
  });

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [warning, setWarning] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [warningTitle, setWarningTitle] = useState("");

  const pageHasBeenRendered = useRef(1);

  // #region ERROR WINDOW
  const handleErrorWindow = () => {
    setError(!error);
    if (errorTitle === "POTREBNA PRIJAVA") {
      navigate("/prijavaUporabnika");
    }
    if (errorTitle === "NEVELJAVNA VLOGA UPORABNIKA" || errorTitle === "NIČELNA VREDNOST") {
      navigate(-1);
    }
  };

  // #region ON LOAD
  useEffect(() => {
    const getCompanyDataFunction = async () => {
      try {
        setIsLoading(true);

        const response = await getCompanyAdministrationData();

        if (response.data?.warning) {
          setWarning(response.data.warning);
          setWarningText(response.data.warningMessage);
          setWarningTitle(ErrorCodesParser(response.data.warningTitle));
        }

        const { company, companiesIds, userId } = response.data;

        setUserId(userId);

        setCompany(company);

        setCompaniesArray({ companiesIds: companiesIds, current: 0 });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(!isLoading);

        setError(true);
        if (!err.response) {
          setErrorText(
            "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
          );
          setErrorTitle("NAPAKA!");
        } else {
          setIsLoading(false);
          setError(true);
          setErrorText(err?.response?.data?.message);
          setErrorTitle(ErrorCodesParser(err?.response.data?.errorCode));
        }
      } finally {
        setIsLoading(false);
      }
    };

    getCompanyDataFunction();
  }, []);

  // #region ON CHANGE COMP
  useEffect(() => {
    const getCompanyPreviewData = async () => {
      setIsLoading(true);
      if (pageHasBeenRendered.current < 2) {
        pageHasBeenRendered.current = pageHasBeenRendered.current + 1;
        return;
      }

      try {
        const response = await getCompanyData(
          companiesArray.companiesIds[companiesArray.current]
        );

        if (response.data?.warning) {
          setWarning(response.data.warning);
          setWarningText(response.data.warningMessage);
          setWarningTitle(ErrorCodesParser(response.data.warningTitle));
        }

        const { company } = response.data;

        setCompany(company);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError(true);
        if (!err.response) {
          setIsLoading(false);
          setErrorText(
            "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
          );
          setErrorTitle("NAPAKA!");
        } else {
          setIsLoading(false);
          setErrorText(err?.response?.data?.message);
          setErrorTitle(ErrorCodesParser(err?.response.data?.errorCode));
        }
      } finally {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsLoading(false);
      }
    };
    getCompanyPreviewData();
  }, [companiesArray.current]);

  // #region PREV company
  const prevCompanyPreview = () => {
    if (companiesArray.current > 0) {
      setCompaniesArray((prev) => ({
        ...prev,
        current: prev.current - 1,
      }));
    } else {
      setError(true);
      setErrorTitle("NAPAKA");
      setErrorText(
        "Ste na podjetju številka 1, če menite da gre za napako (vam kako podjetje manjka) kontaktirajte našo podporno ekipo."
      );
    }
  };

  // #region NEXT company
  const nextCompanyPreview = () => {
    if (companiesArray.current !== companiesArray.companiesIds.length - 1) {
      setCompaniesArray((prev) => ({
        ...prev,
        current: prev.current + 1,
      }));
    } else {
      setError(true);
      setErrorTitle("NAPAKA");
      setErrorText(
        `Ste na podjetju številka ${
          companiesArray.current + 1
        }, če menite da gre za napako (vam kako podjetje manjka) kontaktirajte našo podporno ekipo.`
      );
    }
  };

  const [isOpenUpdateCompanyWindow, setIsOpenUpdateCompanyWindow] =
    useState(false);

  // #region Update Window
  const handleUpdateCompanyWindow = () => {
    setIsOpenUpdateCompanyWindow(!isOpenUpdateCompanyWindow);
  };

  // #region Save company data
  const saveCompanyData = async (newCompany, formData) => {
    setIsLoading(true);
    const { errorFound, errorText, errorTitle } =
      validateCompanyData(newCompany);
    setError(errorFound);
    setErrorText(errorText);
    setErrorTitle(errorTitle);

    if (errorTitle === "DATUM PRIDRUŽITVE PODJETJA") {
      setIsLoading(false);
      const newDate = newCompany.basic.joinDate.split("T");
      // console.log(newDate);
      newCompany.basic.joinDate = newDate;
    }
    if (!errorFound) {
      try {
        const oldImageName = `${company.basic.ID}-${company.legal.taxNumber}`;
        let newImageName = `${company.basic.ID}-${newCompany.legal.taxNumber}`;

        setCompany(newCompany);

        const response = await updateCompanyData(newCompany, false);

        if (response.status !== 200 && formData.current.has("images")) {
          await insertCompanyImages(formData.current);
          setIsLoading(false);
          return;
        }

        if (response.data.errorCode === 604) {
          setIsLoading(false);
          setError(true);
          setErrorTitle("DUPLIKAT PODJETJA");
          setErrorText(
            "Najdeno je bilo podjetje z enakimi podatki preveri te podatke in poskusite znova, ali kontaktirajte našo podporno ekipo"
          );

          return;
        }
        if (response.status === 200) {
          try {
            if (oldImageName !== newImageName) {
              await renameCompanyImages(oldImageName, newImageName);
            }

            if (formData.current.has("images")) {
              await insertCompanyImages(formData.current);
            }

            setIsLoading(false);
            window.location.reload(false);
          } catch (err) {
            setIsLoading(false);
            setError(true);
            setErrorText(err?.response?.data?.message);
            setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
          }
        } else {
          setIsLoading(false);
          setError(errorFound);
          setErrorText(errorText);
          setErrorTitle(errorTitle);
        }
      } catch (err) {
        setIsLoading(false);
        setError(true);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    }
    setIsLoading(false);
    setIsOpenUpdateCompanyWindow(!isOpenUpdateCompanyWindow);
  };

  const [isOpenDeleteCompanyWindow, setIsOpenDeleteCompanyWindow] =
    useState(false);

  // #region Delete window
  const handleDeleteCompayWindow = () => {
    if (isOpenDeleteCompanyWindow) window.location.reload();
    setIsOpenDeleteCompanyWindow(!isOpenDeleteCompanyWindow);
  };

  // #region JSX
  return (
    <div className="page">
      {warning && (
        <WarningWindow
          title={warningTitle}
          text={warningText}
          gobackbtn="Razumem"
          proceedbtn="Uredi"
          goback={() => setWarning(false)}
          proceed={() => {
            handleUpdateCompanyWindow();
            setWarning(!warning);
          }}
        />
      )}

      {error && !isLoading && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      {isLoading && !company && !error && <Loader />}

      {isOpenDeleteCompanyWindow && (
        <DeleteCompanyWindow
          closedeletecompanywindow={handleDeleteCompayWindow}
          id={userId}
        />
      )}

      <Header />

      <div className="main">
        <div className="sidebar">
          <div className="sidebar-box">
            <div className="sidebar-link-box">
              <Link className="sidebar-link" to="/prijavaPodjetja">
                Dodaj podjetje
              </Link>
            </div>
            <div className="sidebar-link-box">
              <span
                className="sidebar-link"
                onClick={handleUpdateCompanyWindow}
              >
                Posodobi podjetje
              </span>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/upravljanjePonudbe"}
                state={{
                  companyIds: companiesArray.companiesIds,
                  offerType: "service",
                }}
              >
                Storitve
              </Link>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/upravljanjePonudbe"}
                state={{
                  companyIds: companiesArray.companiesIds,
                  offerType: "product",
                }}
              >
                Izdelki
              </Link>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/dodajPonudbo"}
                state={{ companyId: company?.basic?.ID, offerType: "service" }}
              >
                Dodaj storitev
              </Link>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/dodajPonudbo"}
                state={{ companyId: company?.basic?.ID, offerType: "product" }}
              >
                Dodaj izdelek
              </Link>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/odstraniPonudbo"}
                state={{ companyId: company?.basic?.ID, offerType: "service" }}
              >
                Odstrani storitev
              </Link>
            </div>
            <div className="sidebar-link-box">
              <Link
                className="sidebar-link"
                to={"/odstraniPonudbo"}
                state={{ companyId: company?.basic?.ID, offerType: "product" }}
              >
                Odstrani izdelek
              </Link>
            </div>
            <div className="sidebar-link-box">
              <span className="sidebar-link" onClick={handleDeleteCompayWindow}>
                Odstrani podjetje
              </span>
            </div>
          </div>
        </div>
        <div className="preview-of-company-page">
          <CloseIcon
            className="reload-page"
            onClick={() => {
              if (!isOpenUpdateCompanyWindow) {
                window.location.reload();
              } else {
                setIsOpenUpdateCompanyWindow(!isOpenUpdateCompanyWindow);
              }
            }}
          />

          {isOpenUpdateCompanyWindow && (
            <UpdateCompanyWindow
              handleupdatecompanywindow={handleUpdateCompanyWindow}
              onsave={saveCompanyData}
              company={company}
            />
          )}

          {!isOpenUpdateCompanyWindow && (
            <>
              {company ? <Company company={company} /> : !error && <Loader />}
              <div className="company-page-box">
                {/* TODO: DODAJ DA SE BO VIDLO NA KERI STRANI JE OZ NA ŠT KEREGA PODJETJA JE  */}
                <div className="administration-button-box">
                  <button
                    className="button-1"
                    onClick={prevCompanyPreview}
                    type="button"
                  >
                    Nazaj
                  </button>
                  <button
                    className="button-1"
                    onClick={nextCompanyPreview}
                    type="button"
                  >
                    Dalje
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyAdministration;
