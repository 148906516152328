import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getCompanyData from "../Services/getCompanyData";
import { ErrorCodesParser } from "../Constants/ErrorCodesParser";
import ErrorWindow from "../Components/ErrorWindow";
import Loader from "../Components/Loader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Company from "./Company";

const CompanyViaUrl = () => {
  const location = useLocation();

  const [company, setCompany] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  // #region GET COMPANY
  const getCompany = async () => {
    try {
      setIsLoading(true);

      if (!location.state?.ID) {
        const pathArr = location.pathname.split("/");

        // console.log(decodeURIComponent(pathArr[pathArr.length - 1]));

        const response = await getCompanyData(
          decodeURIComponent(pathArr[pathArr.length - 1])
        );

        // const response = await getCompanyDataDynamicUrlApi(
        //   process.env.REACCT_APP_GET_COMPANY_DATA_BY_URL
        // );

        // console.log(response);

        const { company } = response.data;
        setCompany(company);
        setIsLoading(false);
        return;
      }
      const response = await getCompanyData(location.state.ID);
      const { company } = response.data;
      setCompany(company);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      if (!err.response) {
        setIsLoading(false);
        setErrorText(
          "Pri nalaganju podatkov je prišlo do napake poskusite znova ali pa se obrnite na našo podporno ekipo!"
        );
        setErrorTitle("NAPAKA!");
      } else {
        setIsLoading(false);
        setErrorText(err?.response?.data?.message);
        setErrorTitle(ErrorCodesParser(err?.response?.data?.errorCode));
      }
    } finally {
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // #region ON LOAD
  useEffect(() => {
    getCompany();
  }, []);

  // #region Handle Error Window
  const handleErrorWindow = () => {
    setError(false);
  };

  // #region JSX
  return (
    <>
      {error && (
        <ErrorWindow
          title={errorTitle}
          text={errorText}
          onclick={handleErrorWindow}
          btntext={"RAZUMEM"}
        />
      )}

      <Header />

      {isLoading && !company && !error && <Loader />}

      {company ? <Company company={company} uselink={true} /> : <Loader />}

      <Footer />
    </>
  );
};

export default CompanyViaUrl;
