import { badWords } from "../../Constants/badWords";
import { EMAIL_REGEX, NUMBER_REGEX, PHONE_NUMBER_REGEX } from "../../Constants/RegexPatterns";

const validateInvoiceReciever = (value) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";
  for (const attribute in value) {
    if (attribute === "invoiceRecieverName") {
      if (containsBadWord(value[attribute])) {
        errorFound = true;
        errorText =
          "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
        errorTitle = "IME PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverLastName") {
      if (value[attribute].length > 0 && containsBadWord(value[attribute])) {
        errorFound = true;
        errorText =
          "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
        errorTitle = "PRIIMEK  PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverTaxNumber") {
      if (!validatingTaxNumber(value[attribute])) {
        errorFound = true;
        errorText =
          "Davčna številka podjetja je neveljavna preverite ali ste kje pri vpisu naredili napako";
        errorTitle = "DAVČNA ŠTEVILKA PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverRegistrationNumber") {
      if (value[attribute].length > 0 && !validatingRegistrationNumber(value[attribute])) {
        errorFound = true;
        errorText =
          "Matična številka podjetja je neveljavna preverite ali ste kje pri vpisu naredili napako";
        errorTitle = "MATIČNA ŠTEVILKA PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverEmail") {
      if (!EMAIL_REGEX.test(value[attribute])) {
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: janez.novak@odkrij.si";
        errorTitle = "E-NASLOV PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverPhoneNumber") {
      if (!PHONE_NUMBER_REGEX.test(value[attribute])) {
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 000-000-000, 000 000 000, 000000000";
        errorTitle = "TELEFONSKA ŠTEVILKA PREJEMNIKA RAČUNA";
      }
    } else if (attribute === "invoiceRecieverTaxPayer"){
      if(!isBoolean(value[attribute])){
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
        errorTitle = "DAVČNI ZAVEZANEC";
      }
    }  else if (attribute === "companyId"){
      if(value[attribute] && !NUMBER_REGEX.test(value[attribute])){
        errorFound = true;
        errorText =
          "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
        errorTitle = "INDEX PODJETJA";
      }
    }
    else {
      // console.log(attribute);
      // console.log(value[attribute]);
      errorFound = true;
      errorText = "Napačen vnos v obrazcu za prejemnika računa!";
      errorTitle = "NAPAČEN VNOS";
    }
  }

  return { errorFound, errorText, errorTitle };
};

export default validateInvoiceReciever;

const validatingTaxNumber = (value) => {
  let sumOfTaxNumber = 0;

  for (let i = 0; i < 8; i++) {
    sumOfTaxNumber += parseInt(value[i] * (8 - i));
  }

  if (value.length === 8 && /^\d+$/.test(value) && sumOfTaxNumber % 11 === 0) {
    return true;
  } else {
    return false;
  }
};

const validatingRegistrationNumber = (value) => {
  let sumOfRegistrationNumber = 0;

  for (let i = 0; i < 6; i++) {
    sumOfRegistrationNumber += parseInt(value[i] * (value.length - 3 - i));
  }

  let controlNumber = 11 - (sumOfRegistrationNumber % 11);

  controlNumber = controlNumber > 9 ? 0 : controlNumber;

  if (value.length === 10 && controlNumber === parseInt(value[6])) {
    return true;
  } else {
    return false;
  }
};

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase)) {
      return true;
    }
  }

  return false;
};

const isBoolean = (value) => {
  return value === true || value === false || value === 0 || value === 1;
};