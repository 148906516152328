import {
  EMAIL_REGEX,
  HOW_LONG_REGEX,
  NUMBER_REGEX,
  PRICE_REGEX,
} from "../../Constants/RegexPatterns";
import { badWords } from "../../Constants/badWords";

const validateOfferData = (data) => {
  let errorFound = false;
  let errorText = "";
  let errorTitle = "";

  let offerType = undefined;

  // console.log(data);
  for (const attribute in data) {
    attribute === "service"
      ? (offerType = "STORITVE")
      : (offerType = "IZDELKA");
    for (const elementOfArr of data[attribute]) {
      for (const attributeOfOffer in elementOfArr) {
        for (const [key, value] of Object.entries(
          elementOfArr[attributeOfOffer]
        )) {
          switch (attributeOfOffer) {
            case "basic":
              switch (key) {
                case "name":
                  if (containsBadWord(value)) {
                    errorFound = true;
                    errorText =
                      "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                    errorTitle = `IME ${offerType}`;
                  }
                  break;
                case "price":
                  if (!PRICE_REGEX.test(value) && !NUMBER_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 00.000,00€";
                    errorTitle = `CENA ${offerType}`;
                  }
                  break;
                case "email":
                  if (!EMAIL_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: ime.priimek@odkrij.si";
                    errorTitle = `E-NASLOV ${offerType}`;
                  }
                  break;
                case "warrantyTime":
                  if (!HOW_LONG_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0 dni";
                    errorTitle = `ČAS GARANCIJE ${offerType}`;
                  }
                  break;
                case "category":
                  if (!NUMBER_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                    errorTitle = `KATEGORIJA ${offerType}`;
                  }
                  break;
                case "type":
                  if (containsBadWord(value)) {
                    errorFound = true;
                    errorText =
                      "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                    errorTitle = `VRSTA ${offerType}`;
                  }
                  break;
                case "time":
                  if (!HOW_LONG_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0 dni";
                    errorTitle = `ČAS ${offerType}`;
                  }
                  break;
                case "returnConditions":
                  if (containsBadWord(value)) {
                    errorFound = true;
                    errorText =
                      "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                    errorTitle = `POGOJI ZA VRAČILO ${offerType}`;
                  }
                  break;
                case "text":
                  if (containsBadWord(value)) {
                    errorFound = true;
                    errorText =
                      "Vaš prispevek vsebuje besede, ki niso dovoljene na naši platformi. Prosimo vas, da svoj tekst uredite in odstranite vse neprimerne izraze ter ponovno poskusite.";
                    errorTitle = `OPIS ${offerType}`;
                  }
                  break;
                case "quantity":
                  if (!NUMBER_REGEX.test(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0";
                    errorTitle = `KOLIČINA ${offerType}`;
                  }
                  break;
                default:
                  errorFound = true;
                  errorText = `Napačen vnos v razdelku osnovni podatki!`;
                  errorTitle = "NAPAČEN VNOS";
                  break;
              }
              break;
            case "flags":
              switch (key) {
                case "return":
                  if (!isBoolean(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                    errorTitle = "VRAČILO IZDELKA";
                  }
                  break;
                case "warranty":
                  if (!isBoolean(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                    errorTitle = "GARANCIJA IZDELKA";
                  }
                  break;
                case "orderItem":
                  if (!isBoolean(value)) {
                    errorFound = true;
                    errorText =
                      "Oblika vašega vnosa ne ustreza našim standardom vaša oblika mora biti podobna: 0/1";
                    errorTitle = `NAROČILO: ${offerType}`;
                  }
                  break;
                default:
                  errorFound = true;
                  errorText = errorText = "Napačen vnos v razdelku zastavice!";
                  errorTitle = "NAPAČEN VNOS";
                  break;
              }
              break;
            default:
              errorFound = true;
              errorText = "Napačen vnos v obrazcu za prijavo ponudbe!";
              errorTitle = "NAPAČEN VNOS";
              break;
          }
        }
      }
    }
  }

  return { errorFound, errorText, errorTitle };
};

export default validateOfferData;

const isBoolean = (value) => {
  return value === true || value === false;
};

const containsBadWord = (value) => {
  const words = value.split(" ");
  for (const element of words) {
    if (badWords.includes(element.toLowerCase())) {
      return true;
    }
  }

  return false;
};
