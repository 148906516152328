import React from "react";
import { LuArrowLeftFromLine as ExitArrow } from "react-icons/lu";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";

import "../../Assets/Styles/Pages/ErrorPages/NotFoundPage.css";
import "../../Assets/Styles/Config/Icons.css";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="error-pages-main">
        <div className="error-pages-return-btn-box" onClick={() => navigate("/")}>
          <ExitArrow className="exit-arrow" />
          <p className="error-pages-return-btn-text">Nazaj na začetno stran</p>
        </div>
        <div className="error-pages-error-code">404</div>
        <div className="error-pages-error-message">
          Stran, ki jo iščete, ne obstaja. Preverite, ali ste se morda zmotili
          pri vnosu naslova.
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;
