import { NUMBER_REGEX } from "../../Constants/RegexPatterns";

const categoryParser = (categoryKey) => {
  switch (
    NUMBER_REGEX.test(categoryKey) ? parseInt(categoryKey) : categoryKey
  ) {
    case 1:
      return "Energetika";
    case 2:
      return "Gradbeništvo";
    case 3:
      return "Nepremičnine";
    case 4:
      return "Proizvodnja";
    case 5:
      return "Izobraževanje";
    case 6:
      return "Kmetijstvo, gozdarstvo in ribištvo";
    case 7:
      return "Kultura";
    case 8:
      return "Transport";
    case 9:
      return "Turizem in gostinstvo";
    case 10:
      return "Trgovina in storitve";
    case 11:
      return "Finančne storitve";
    case 12:
      return "Tehnologija in informacijske storitve";
    case 13:
      return "Industrija";
    case 14:
      return "Poslovne storitve";
    case "kategorija":
      return "kategorija";
    default:
      return "Kategorija ni na voljo! ";
  }
};

export default categoryParser;
