import React from "react";
import "../Assets/Styles/Components/TextArea.css";

const TextArea = (props) => {
  return (
    <div className="textarea-container">
      <div className="textarea-box">
        <label className={props.labelclass} htmlFor={props.id}>
          {props.label}
        </label>
        <div className="textarea-wrapper">
          <textarea
            id={props.id}
            name={props.name}
            rows={props.rows}
            cols={props.cols}
            placeholder={props.placeholder}
            onChange={(e) => props.onchange(e, props.datatype)}
            defaultValue={props.defaultvalue}
            className="textarea"
          >
            {props.text}
          </textarea>
        </div>
        <span className={props.char <= props.minChar ? "char-count" : "char-count-over"}>
          {props.char}/{props.minChar}
        </span>
      </div>
    </div>
  );
};

export default TextArea;
